import { Form, Formik } from 'formik';
import { Input, showToast } from '@bp/ui-components';
import { FC } from 'react';
import {
  useBpCreateTeachingTopicMutation,
  useBpRenameTopicMutation,
  useBpTopicsQuery,
} from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { schema } from './validation/schema';
import { useTranslation } from 'react-i18next';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { UserStatus } from '@bp/bp-graphql-types';

type TeachingTopicFormProps = {
  topicUuid?: string | null;
  parentTopicUuid?: string | null;
  courseUuid: string;
  onClose: () => void;
};

type TeachingTopicFormValues = { title: string };

export const TeachingTopicForm: FC<TeachingTopicFormProps> = ({ parentTopicUuid, topicUuid, courseUuid, onClose }) => {
  const edit = !!topicUuid;
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const [, createTeachingTopic] = useBpCreateTeachingTopicMutation();
  const [, editTopic] = useBpRenameTopicMutation();

  const context = useMemoizedCacheTag('TEACHING_UNIT');
  const [{ data }] = useBpTopicsQuery({
    context,
    variables: {
      where: {
        uuid: topicUuid,
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      },
    },
  });

  const initialValues: TeachingTopicFormValues = {
    title: edit ? (data?.teachingTopics[0]?.title ?? '') : '',
  };

  const handleSubmit = async (values: TeachingTopicFormValues) => {
    if (topicUuid && edit) {
      await editTopic(
        {
          where: {
            uuid: topicUuid,
          },
          update: {
            title: values.title,
          },
        },
        context,
      );
    } else {
      /*
       Create TeachingTopic
       */
      const owner = {
        connectOrCreate: {
          where: {
            node: {
              uuid: pimAuthClaims.getProfile().uuid ?? '',
            },
          },
          onCreate: {
            node: {
              uuid: pimAuthClaims.getProfile().uuid ?? '',
              managedBy: 'bp',
              userStatus: UserStatus.None,
              foreignRefId: [],
            },
          },
        },
      };
      const group = {
        connect: {
          where: {
            node: {
              uuid: courseUuid,
            },
          },
        },
      };
      const { error } = await createTeachingTopic(
        {
          input: [
            {
              title: values?.title,
              owner: owner,
              organization: {
                connect: { where: { node: { uuid: organizationUuid } } },
              },
              group: group,
              topicParent: { connect: { where: { node: { uuid: parentTopicUuid } } } },
            },
          ],
        },
        context,
      );
      if (error) {
        showToast(t('common.error'), {
          type: 'error',
        });
      } else {
        showToast(t('common.saved'), {
          type: 'success',
        });
      }
    }
    onClose();
  };

  return (
    <Formik<TeachingTopicFormValues>
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={schema}
    >
      {({ handleBlur, handleChange, values, isSubmitting, errors }) => {
        return (
          <Form className={'bp__form'}>
            <Input
              autoFocus={true}
              label={t('common.title')}
              name={'title'}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.title?.toString()}
              error={errors.title?.toString()}
            />
            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
