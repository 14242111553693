import React, { useMemo } from 'react';
import { BulkEditIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { ProcuratPerson } from './hooks/useProcuratGroups';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { niceDate } from '../../utils/dateCalculations';
import { _ProfilesInfoQuery } from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { profileRoleAsString } from '../../utils/roleAsString';

export type ProcuratDepartmentPersonsTableData = ProcuratPerson & {
  lastImport: string;
  imported: boolean;
  profile: string;
  roles: string[];
};

type ProcuratClassesTableProps = {
  data: ProcuratDepartmentPersonsTableData[];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  handleImport: () => void;
  profilesData: _ProfilesInfoQuery | undefined;
};

export const ProcuratDepartmentPersonsTable = ({
  data,
  rowSelection,
  onRowSelectionChange,
  handleImport,
  profilesData,
}: ProcuratClassesTableProps) => {
  const { t } = useTranslation();

  const personColumns: TableColumns<ProcuratDepartmentPersonsTableData>[] = useMemo(() => {
    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        canExpand: true,
        cell: (cell) => {
          return `${cell.row.original.firstName} ${cell.row.original.lastName}`;
        },
      },
      {
        header: t('common.role'),
        id: 'role',
        accessorKey: 'roles',
        accessorFn: (row) => row.roles.map((r) => profileRoleAsString(r)).join(', '),
        canExpand: true,
        type: 'string',
      },
      {
        id: 'lastImport',
        accessorKey: 'lastImport',
        header: t('common.lastUpdated'),
        cell: (cell) => {
          return niceDate(cell.row.original.lastImport, 'short', 'short') ?? '-';
        },
        type: 'string',
      },
      {
        id: 'imported',
        accessorKey: 'imported',
        header: t('common.imported'),
        type: 'boolean',
        size: 150,
      },
    ];
  }, [profilesData?.profiles]);

  return (
    <>
      <Table<ProcuratDepartmentPersonsTableData>
        showActionBar
        actionBarSettings={{
          showSelectedRowsCount: true,
          showExpertFilter: false,
          globalFilterPlaceholder: t('common.name'),
          extendedActionsRight: (
            <Button
              hierarchy='tertiary'
              icon={<BulkEditIcon />}
              onClick={handleImport}
              disabled={Object.keys(rowSelection).length < 1}
            >
              {t('institution.importPersons')}
            </Button>
          ),
        }}
        columns={personColumns}
        data={data}
        onRowSelectionChange={onRowSelectionChange}
        rowSelection={rowSelection}
        canScroll={true}
        showSelect={true}
      />
    </>
  );
};
