import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { ClassesList } from 'components/Classes/ClassesList';

export type BpClassType = {
  uuid: string;
  name: string;
  shortName: string;
  grade: number;
  gradeGroup: string;
  tutor1Uuid: string;
  tutor2Uuid: string;
  tutor3Uuid: string;
  color: {
    color: string;
    colorLabel: string;
  };
  source: string;
  divisionUuids: string[];
  profileUuids: string[];
};

export type BpDivisionType = {
  uuid: string;
  name: string;
  classUuid: string;
  classGroupUuids: string[];
  source: string;
};

export type BpClassGroupsType = {
  uuid: string;
  divisionUuid: string;
  name: string;
  shortName: string;
  profileUuids: string[];
  source: string;
};

export const InstitutionClassesSubpage = () => {
  return (
    <BpSubpage>
      <ClassesList />
    </BpSubpage>
  );
};
