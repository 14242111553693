import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useCallback, useEffect } from 'react';
import { isIosOrAndroid } from 'utils/isIosOrAndroid';
import React from 'react';
import { captureException } from '@sentry/react';
import {
  useAddPushIdsInNotificationSettingsMutation,
  useCreatePushIdIfNoneIsDefinedInNotificationSettingsMutation,
  useGetNotificationSettingsForUserQuery,
} from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';

type PushNotificationWrapperProps = {
  children: React.ReactNode;
};

export const PushNotificationWrapper = ({ children }: PushNotificationWrapperProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const userUuid = pimAuthClaims.getUser().uuid;

  const [{ data: notificationData }] = useGetNotificationSettingsForUserQuery({
    variables: {
      where: {
        userUuid,
      },
    },
  });
  const notificationSettingsExistForUser = notificationData?.notificationSettings.some((n) => n.userUuid === userUuid);

  const [, createPushId] = useCreatePushIdIfNoneIsDefinedInNotificationSettingsMutation();
  const [, addPushId] = useAddPushIdsInNotificationSettingsMutation();

  const saveTokenInDB = useCallback(
    async (token: string) => {
      if (notificationSettingsExistForUser) {
        await addPushId({
          add: {
            pushIds_PUSH: [token],
            userUuid,
          },
        });
      } else {
        await createPushId({
          input: {
            pushIds: [token],
            userUuid,
          },
        });
      }
    },
    [createPushId, addPushId, userUuid, notificationSettingsExistForUser],
  );

  useEffect(() => {
    const currentPlatform = Capacitor.getPlatform();
    if (!isIosOrAndroid(currentPlatform)) return;

    const init = async () => {
      const { remove: removeRegistrationListener } = await listenToRegistration(saveTokenInDB);
      const { remove: removeErrorListener } = await listenToError();
      await registerNotifications();

      return () => {
        removeRegistrationListener();
        removeErrorListener();
      };
    };
    init();
  }, [saveTokenInDB]);

  return children;
};

const listenToRegistration = async (onTokenReturn: (token: string) => Promise<void>) =>
  PushNotifications.addListener('registration', async (token) => {
    await onTokenReturn(token.value);
  });

const listenToError = async () =>
  PushNotifications.addListener('registrationError', (err) => {
    captureException(err);
  });

const registerNotifications = async () => {
  let permStatus = await PushNotifications.requestPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    await PushNotifications.register();
  }
};
