import { useTranslation } from 'react-i18next';
import {
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  GroupsIcon,
  LazyLoader,
  ProfileIcon,
  Row,
  SendIcon,
  Table,
  Tooltip,
  VisibleIcon,
} from '@bp/ui-components';
import { FC, PropsWithChildren, useMemo } from 'react';
import { UserStatus } from '@bp/bp-graphql-types';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { profileHasUser } from './profileHasUser';
import styles from './PersonsList.module.scss';
import classNames from 'classnames';

export type ProfileElement = {
  uuid: string;
  displayName: string;
  organization: string;
  profileRole: ProfileRoles;
  isEditor: boolean;
  userStatus?: UserStatus;
  email?: string;
  canMessage?: boolean;
};

type PersonsListProps = PropsWithChildren & {
  persons: ProfileElement[];
  organizationUuid: string;
  isEditable?: boolean;
  loading?: string | null; // the uuid of loading person
  maxHeight?: number;
  onChat?: (person: ProfileElement) => void;
  onToggle?: (uuid: string, isEditor: boolean) => void;
  onProfile?: (uuid: string) => void;
};

export const PersonsList: FC<PersonsListProps> = ({
  persons,
  organizationUuid,
  isEditable = false,
  loading,
  maxHeight,
  onChat,
  onToggle,
  onProfile,
}) => {
  const { t } = useTranslation();

  const personData = useMemo(() => {
    return persons.sort((a, b) => {
      if (a.isEditor === b.isEditor) {
        return a.displayName.localeCompare(b.displayName);
      } else {
        return b.isEditor ? 1 : -1;
      }
    });
  }, [persons]);

  return (
    <Table
      className={styles['persons-list']}
      canScroll
      hideHeader
      maxHeight={maxHeight}
      breakpoint={null}
      customPadding='var(--spacing-6)'
      customLastColSpacing='var(--spacing-4)'
      columns={[
        {
          id: 'displayName',
          accessorKey: 'displayName',
          canExpand: true,
          cell: ({ row }) => (
            <div className={styles['name-col']}>
              <div>{row.original.displayName}</div>
            </div>
          ),
        },
      ]}
      data={personData}
      emptyStateSettings={{
        icon: <GroupsIcon />,
        iconColor: 'var(--color-primary-light)',
        subtitle: t('persons.noPerson'),
        fitParent: true,
        size: 'small',
        forcedHeight: '450px',
      }}
      lastColWidth={onToggle ? '125px' : '85px'}
      lastCol={(row: Row<ProfileElement>) => {
        const person = row.original;

        return (
          <>
            {loading === person.uuid ? (
              <LazyLoader embedded size='m' forceWidth='95px' />
            ) : (
              <>
                {onToggle &&
                  (person.isEditor ? (
                    <Tooltip content={t('personsList.changeToViewer')}>
                      <Button
                        hierarchy='tertiary'
                        disabled={!isEditable}
                        icon={<EditIcon />}
                        {...(isEditable ? { onClick: () => onToggle(person.uuid, person.isEditor) } : undefined)}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip content={t('personsList.changeToEditor')}>
                      <Button
                        hierarchy='tertiary'
                        disabled={!isEditable}
                        icon={<VisibleIcon />}
                        {...(isEditable ? { onClick: () => onToggle(person.uuid, person.isEditor) } : undefined)}
                      />
                    </Tooltip>
                  ))}
                {person.canMessage && onChat ? (
                  <Tooltip content={t('common.sendMessage')}>
                    <Button hierarchy='tertiary' icon={<SendIcon />} onClick={() => onChat(person)} />
                  </Tooltip>
                ) : (
                  <Button hierarchy='tertiary' icon={<SendIcon />} disabled />
                )}
                {!profileHasUser(person.userStatus) && person.organization === organizationUuid && (
                  <Dropdown trigger={<Button hierarchy='tertiary' icon={<DotsVerticalIcon />} />}>
                    <DropdownMenu
                      data={[
                        {
                          label: t('personsList.createUser'),
                          disabled: !onProfile,
                          onClick: onProfile ? () => onProfile(person.uuid) : undefined,
                        },
                      ]}
                    />
                  </Dropdown>
                )}

                {profileHasUser(person.userStatus) && (
                  <Tooltip content={t('personsList.hasUser')} triggerClass={styles['profile-wrapper']}>
                    <ProfileIcon className={classNames('svg-icon', styles.profile)} />
                  </Tooltip>
                )}

                {!profileHasUser(person.userStatus) && person.organization !== organizationUuid && (
                  <Tooltip content={t('personsList.hasNoUser')} triggerClass={styles['profile-wrapper']}>
                    <div></div>
                  </Tooltip>
                )}
              </>
            )}
          </>
        );
      }}
    />
  );
};
