import * as Yup from 'yup';
import i18next from 'i18next';

export const courseFormSchema = Yup.object().shape({
  name: Yup.string().required(i18next.t('validation.required.designation')),
  groups: Yup.array().of(Yup.string()),
  grades: Yup.array().of(Yup.string()),
  classes: Yup.array().of(Yup.string()),
  schoolyear: Yup.string().required(i18next.t('validation.required.schoolyear')).length(36), // length of uuid
});
