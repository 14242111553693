export type NotificationTypes = 'bell' | 'push' | 'mail';

export type NotificationSettingType = {
  name: string;
  value: NotificationTypes[];
}[];

export enum NotificationOptions {
  NewMaterial = 'newMaterial',
  MaterialUpdated = 'materialUpdated',
  MaterialDeleted = 'materialDeleted',
  NewMessage = 'newMessage',
  AssignmentOpened = 'assignmentOpened',
  AssignmentUpdated = 'assignmentUpdated',
  AssignmentDeleted = 'assignmentDeleted',
  SubmissionNew = 'submissionNew',
  SubmissionUpdated = 'submissionUpdated',
  NewAnnouncement = 'newAnnouncement',
  NewGroupMembership = 'newGroupMembership',
  NewCourseMembership = 'newCourseMembership',
  NewCollaboratingCourseOrGroup = 'newCollaboratingCourseOrGroup',
  ConferenceStarted = 'conferenceStarted',
  NewAppointment = 'newAppointment',
}
