import {
  AddIcon,
  Dropdown,
  DropdownMenu,
  EmptyState,
  Grid,
  GridColumn,
  GridRow,
  InvisibleIcon,
  LazyLoader,
  Modal,
} from '@bp/ui-components';
import { use_BpTeachingUnitsByGroupQuery, useBpGetTopicsByGroupQuery } from '../../../client/bp-graphql-client-defs';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { TeachingUnit } from '../../../components/TeachingUnit/TeachingUnit';
import { DesktopContext } from '../../../context/IsDesktop';
import { TeachingUnitContext } from '../../../context/TeachingUnitContext';
import { useMemoizedCacheTag } from '../../../hooks/useMemoizedCacheTag';
import { FC, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AssignmentForm } from '../../../components/AssignmentForm/AssignmentForm';
import { AppointmentForm } from '../../../components/AppointmentForm/AppointmentForm';
import { TeachingUnitNoteForm } from '../../../components/TeachingUnitNoteForm/TeachingUnitNoteForm';
import { TeachingUnitForm } from '../../../components/TeachingUnitForm/TeachingUnitForm';
import { BpCard } from '../../../components/BpCard/BpCard';
import { TeachingTopicForm } from '../../../components/TeachingTopicForm/TeachingTopicForm';
import { TeachingUnitsTree } from '../../../components/TeachingUnitsTree/TeachingUnitsTree';
import { CourseWorkMaterialsForm } from '../../../components/WorkMaterialsForm/CourseWorkMaterialsForm';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { useWindowDimensions } from 'utils/dimensions';

type ActiveType = 'teachingUnit' | 'teachingTopic' | 'assignment' | 'workmaterial' | 'appointment' | 'notes' | null;

export const CourseLessonsSubpage: FC = () => {
  const [modalOpen, setModalOpen] = useState<ActiveType>(null);
  const { isDesktop } = useContext(DesktopContext);
  const navigate = useNavigate();
  const { courseUuid } = useParams<{ courseUuid: string }>();
  const { t } = useTranslation();
  const perms = usePermissionChecker();

  const { isPhone, isTablet } = useWindowDimensions();

  const { teachingUnitUuid: contextUuid, setTeachingUnitUuid } = useContext(TeachingUnitContext);
  const { teachingUnitUuid: paramsUuid } = useParams<{ teachingUnitUuid: string }>();

  const [assignmentUuid, setAssignmentUuid] = useState<string | undefined>();
  const [workMaterialUuid, setWorkMaterialUuid] = useState<string | undefined>();
  const [appointmentUuid, setAppointmentUuid] = useState<string | undefined>();
  const [isNew, setIsNew] = useState<boolean>(false);
  const [parentTopicUuid, setParentTopicUuid] = useState<string | null>();
  const [topicUuid, setTopicUuid] = useState<string>();
  const context = useMemoizedCacheTag('TEACHING_UNIT');

  const [{ data: teachingUnitsData }] = use_BpTeachingUnitsByGroupQuery({
    variables: {
      where: {
        uuid: courseUuid,
      },
    },
    context,
  });

  const [{ data: topicsData }] = useBpGetTopicsByGroupQuery({
    variables: {
      where: {
        uuid: courseUuid,
      },
    },
    context,
  });

  const teachingTopics = topicsData?.groups[0]?.teachingTopics ? topicsData.groups[0].teachingTopics : [];

  const rootTopic = teachingTopics.find((teachingTopic) => {
    return teachingTopic.topicParentConnection.edges.length === 0;
  });
  const firstFromDB = teachingUnitsData?.groups[0]?.teachingUnits[0]?.uuid;

  const teachingUnitUuidToUse = paramsUuid ? paramsUuid : contextUuid ? contextUuid : firstFromDB ? firstFromDB : '';

  useEffect(() => {
    navigate(`/courses/${courseUuid}/lessons/${teachingUnitUuidToUse}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseUuid, teachingUnitUuidToUse]);

  const handleAdd = (type: ActiveType, parentUuid: string) => {
    setIsNew(true);
    setParentTopicUuid(parentUuid);
    setModalOpen(type);
  };

  const handleEdit = (type: ActiveType, uuid: string) => {
    setIsNew(false);
    setTopicUuid(uuid);
    setModalOpen(type);
  };

  const handleClose = () => {
    setIsNew(false);
    setModalOpen(null);
    setAssignmentUuid(undefined);
    setWorkMaterialUuid(undefined);
    setAppointmentUuid(undefined);
  };

  const organizationUuid = useMemo(() => {
    return teachingUnitsData?.groups[0].teachingUnits[0]?.organization?.uuid ?? '';
  }, [teachingUnitsData]);

  const isGroupEditor =
    !!perms?.isGroupEditor({
      uuid: courseUuid ?? '',
      organization: { uuid: organizationUuid },
    }) ||
    !!perms?.isGroupAdmin({
      uuid: courseUuid ?? '',
      organization: { uuid: organizationUuid },
    });

  if (!isGroupEditor) {
    return (
      <EmptyState
        icon={<InvisibleIcon />}
        iconColor='var(--color-error)'
        title={t('errors.noAccess')}
        subtitle={t('errors.noAccessHint')}
        fitParent
        forcedHeight='30vh'
      />
    );
  }

  const teachingUnit = teachingUnitsData?.groups[0].teachingUnits.find((t) => t.uuid === teachingUnitUuidToUse);

  const TeachingUnitsTreeCard = (
    <BpCard
      header={{
        headline: t('teachingUnits.title'),
        actions: [
          {
            text: '',
            icon: (
              <Dropdown noPadding trigger={<AddIcon />}>
                <DropdownMenu
                  data={[
                    {
                      label: t('teachingUnits.titleSingular'),
                      onClick: () => {
                        if (!rootTopic) return;
                        handleAdd('teachingUnit', rootTopic.uuid);
                      },
                    },
                    {
                      label: t('teachingTopics.titleSingular'),
                      onClick: () => {
                        if (!rootTopic) return;
                        handleAdd('teachingTopic', rootTopic.uuid);
                      },
                    },
                  ]}
                />
              </Dropdown>
            ),
          },
        ],
      }}
    >
      <Suspense fallback={<LazyLoader forceHeight='30vh' embedded />}>
        <TeachingUnitsTree
          isEditable
          courseUuid={courseUuid}
          activeTeachingUnitUuid={teachingUnitUuidToUse}
          onTeachingUnitSelect={(uuid: string) => {
            setTeachingUnitUuid(uuid);
            navigate(`/courses/${courseUuid}/lessons/${uuid}`);
          }}
          onTeachingUnitAdd={(parentUuid) => {
            if (!rootTopic) return;
            handleAdd('teachingUnit', parentUuid ?? rootTopic.uuid);
          }}
          onTopicAdd={(parentUUid) => handleAdd('teachingTopic', parentUUid)}
          onTopicEdit={(uuid) => handleEdit('teachingTopic', uuid)}
        />
      </Suspense>
    </BpCard>
  );

  return (
    <BpSubpage>
      <Grid>
        {(isPhone || isTablet) && <GridRow>{TeachingUnitsTreeCard}</GridRow>}
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={8}>
            {!teachingUnit ? (
              <EmptyState
                forcedHeight='25vh'
                fitParent
                title={t('teachingUnits.noTeachingUnits')}
                iconColor='var(--color-primary-light)'
              />
            ) : (
              <TeachingUnit
                teachingUnit={{
                  uuid: teachingUnit.uuid,
                  title: teachingUnit.title,
                  workMaterials: teachingUnit.workMaterials.map((wm) => {
                    return {
                      uuid: wm.uuid,
                      name: wm.title,
                      relevantFrom: wm.visibleFrom?.date ?? '',
                      relevantTo: wm.visibleTo?.date ?? '',
                      visibleFrom: wm.publicFrom?.date ?? '',
                      visibleAfterVisibleTo: !!wm.visibleAfterVisibleTo,
                    };
                  }),
                  note: {
                    uuid: teachingUnit.note?.uuid ?? '',
                    text: teachingUnit.note?.text ?? '',
                    fileEntries: teachingUnit.note?.fileEntries ?? [],
                  },
                  description: teachingUnit.description ?? '',
                  assignments:
                    teachingUnit.assignments.map((a) => {
                      return {
                        uuid: a.uuid,
                        name: a.title,
                        dueDate: a.dueDate?.date ?? '',
                        visibleFrom: a.visibleFrom?.date ?? '',
                      };
                    }) ?? [],
                }}
                onTeachingUnitEdit={() => {
                  setModalOpen('teachingUnit');
                }}
                onNewAssignment={() => {
                  setModalOpen('assignment');
                }}
                onNewWorkMaterial={() => {
                  setModalOpen('workmaterial');
                }}
                onEventEdit={(eventUuid) => {
                  setAppointmentUuid(eventUuid);
                  setModalOpen('appointment');
                }}
                onAssignmentEdit={(assignmentUuid) => {
                  setAssignmentUuid(assignmentUuid);
                  setModalOpen('assignment');
                }}
                onWorkMaterialEdit={(workmaterialUuid) => {
                  setWorkMaterialUuid(workmaterialUuid);
                  setModalOpen('workmaterial');
                }}
                onNoteEdit={(materialUuid) => {
                  setModalOpen('notes');
                }}
                onNewAppointment={() => {
                  setModalOpen('appointment');
                }}
                onAssignmentNavigate={(assignmentUuid: string) => {
                  navigate(`/courses/${courseUuid}/assignments/${assignmentUuid}`);
                }}
                onAppointmentNavigate={(appointmentUuid: string): void => {
                  navigate(`/courses/${courseUuid}/appointments/${appointmentUuid}`);
                }}
                onWorkMaterialNavigate={(workMaterialUuid: string): void => {
                  navigate(`/courses/${courseUuid}/workmaterials/${workMaterialUuid}`);
                }}
              />
            )}
          </GridColumn>
          {!isPhone && !isTablet && <GridColumn width={4}>{TeachingUnitsTreeCard}</GridColumn>}
        </GridRow>
      </Grid>

      {teachingUnitUuidToUse && courseUuid && (
        <Modal
          isOpen={modalOpen === 'assignment'}
          onRequestClose={() => handleClose()}
          hideFooter
          hideHeader
          isFormModal
        >
          <Suspense fallback={<LazyLoader forceHeight='25vh' embedded />}>
            <AssignmentForm
              assignmentUuid={assignmentUuid}
              currentTeachingUnitUuid={teachingUnitUuidToUse}
              courseUuid={courseUuid}
              isModal
              onClose={() => handleClose()}
              context={context}
              isGroupEditor={isGroupEditor}
            />
          </Suspense>
        </Modal>
      )}

      {teachingUnitUuidToUse && (
        <Modal isOpen={modalOpen === 'workmaterial'} onRequestClose={handleClose} hideFooter hideHeader isFormModal>
          <Suspense fallback={<LazyLoader forceHeight='30vh' embedded />}>
            <CourseWorkMaterialsForm
              workMaterialUuid={workMaterialUuid}
              currentTeachingUnitUuid={teachingUnitUuidToUse}
              isModal
              isDesktop={isDesktop}
              onClose={handleClose}
              context={context}
            />
          </Suspense>
        </Modal>
      )}

      {courseUuid && (
        <Modal
          isOpen={modalOpen === 'appointment'}
          onRequestClose={() => handleClose()}
          hideHeader
          hideFooter
          isFormModal
        >
          <Suspense fallback={<LazyLoader forceHeight='30vh' embedded />}>
            <AppointmentForm
              isModal
              holderUuid={courseUuid}
              context='GroupOrCourse'
              onClose={() => handleClose()}
              appointmentUuid={appointmentUuid}
              teachingUnitUuid={teachingUnitUuidToUse}
            />
          </Suspense>
        </Modal>
      )}

      {teachingUnitUuidToUse && (
        <Modal isOpen={modalOpen === 'notes'} onRequestClose={() => handleClose()} title={t('notes.edit')} hideFooter>
          <Suspense fallback={<LazyLoader forceHeight='30vh' embedded />}>
            <TeachingUnitNoteForm onClose={handleClose} teachingUnitUuid={teachingUnitUuidToUse} />
          </Suspense>
        </Modal>
      )}

      {courseUuid && (
        <Modal
          isOpen={modalOpen === 'teachingUnit'}
          onRequestClose={() => handleClose()}
          hideFooter
          title={
            isNew
              ? t('common.createType', {
                  type: t('teachingUnits.titleSingular'),
                })
              : t('common.editType', {
                  type: t('teachingUnits.titleSingular'),
                })
          }
        >
          <Suspense fallback={<LazyLoader forceHeight='30vh' embedded />}>
            <TeachingUnitForm
              teachingUnitUuid={isNew ? null : teachingUnitUuidToUse}
              courseUuid={courseUuid}
              onClose={() => handleClose()}
            />
          </Suspense>
        </Modal>
      )}

      {courseUuid && (
        <Modal
          isOpen={modalOpen === 'teachingTopic'}
          onRequestClose={() => handleClose()}
          width='s'
          title={
            isNew
              ? t('common.createType', {
                  type: t('teachingTopics.titleSingular'),
                })
              : t('common.editType', {
                  type: t('teachingTopics.titleSingular'),
                })
          }
        >
          <TeachingTopicForm
            parentTopicUuid={parentTopicUuid}
            topicUuid={isNew ? null : topicUuid}
            courseUuid={courseUuid}
            onClose={() => handleClose()}
          />
        </Modal>
      )}
    </BpSubpage>
  );
};
