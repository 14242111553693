import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Checkbox, DatePicker, Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import {
  BpCreateBpProfileMutation,
  UpdateBpProfileMutation,
  useBpCreateBpProfileMutation,
  useUpdateBpProfileMutation,
} from '../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import * as Yup from 'yup';
import { ProfileTableData } from '../../pages/Institution/subpages/InstitutionPersonsSubpage';
import { OperationResult } from 'urql';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { UserStatus } from '@bp/bp-graphql-types';

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  salutation: string;
  prefixName: string;
  birthday?: Date;
  role: ProfileRoles;
  organisationRoles: Array<string | null>;
  invite: boolean;
};

type Props = { setModalClosed: (close: boolean) => void; data: ProfileTableData | null; role: ProfileRoles };

export const AddProfileForm = ({ setModalClosed, data, role }: Props) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const perms = usePermissionChecker();
  const [, createProfile] = useBpCreateBpProfileMutation();
  const [, updateBpProfile] = useUpdateBpProfileMutation();

  const formikValues: FormData = {
    email: data?.email ?? '',
    title: data?.title ?? '',
    salutation: data?.salutation ?? '',
    prefixName: data?.prefixName ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    role: role,
    organisationRoles: data?.organizationRoles ?? [],
    birthday: data?.birthday ? new Date(data.birthday) : undefined,
    invite: false,
  };

  const handleSubmit = async (values: typeof formikValues) => {
    const handleResponse = (resp: OperationResult<UpdateBpProfileMutation | BpCreateBpProfileMutation>) => {
      if (!resp.error) {
        setModalClosed(true);
        showSuccessToast(t('profiles.profileSaved'));
      }

      if (resp.error) {
        setModalClosed(true);
        showErrorToast(resp.error);
      }
    };

    if (perms?.canCreateProfile({ uuid: organizationUuid })) {
      if (data) {
        const resp = await updateBpProfile({
          update: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            prefixName: values.prefixName,
            salutation: values.salutation,
            title: values.title,
            birthday: values.birthday ? new Date(values.birthday).toISOString() : '',
            invite: values.email !== data.email ? true : values.invite, // invite when email changed
            frontendUri: import.meta.env.VITE_APP_REDIRECT_URI,
            organizationRoles: values.organisationRoles.filter((r) => r !== null) ?? [],
          },
          uuid: data.uuid,
        });

        handleResponse(resp);
      }

      if (!data && role) {
        const resp = await createProfile({
          input: {
            organizationRoles: [values.role],
            createAndInviteUser: values.invite,
            organization: organizationUuid,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            prefixName: values.prefixName,
            salutation: values.salutation,
            title: values.title,
            birthday: values.birthday ? new Date(values.birthday).toISOString() : '',
            frontendUri: import.meta.env.VITE_APP_REDIRECT_URI,
            foreignRefId: [],
          },
        });

        handleResponse(resp);
      }
    }
  };

  const validationSchema = Yup.object({
    invite: Yup.boolean(),
    email: Yup.string().when('invite', ([invite], schema) => {
      return invite ? schema.email().required(t('validation.required.email')) : schema.email();
    }),
    firstName: Yup.string().required(t('validation.required.firstName')).min(2, t('validation.textTooShort')),
    lastName: Yup.string().required(t('validation.required.lastName')).min(2, t('validation.textTooShort')),
    birthday: Yup.date(),
    salutation: Yup.string(),
    prefixName: Yup.string(),
    title: Yup.string(),
  });

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues} validationSchema={validationSchema}>
      {({ resetForm, isSubmitting, errors, handleChange, setFieldValue, values }) => {
        const onClose = () => {
          resetForm();
          setModalClosed(true);
        };

        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <GridColumn width={6}>
                  <Input
                    error={errors.salutation}
                    onChange={handleChange}
                    name={'salutation'}
                    label={t('persons.salutation')}
                    value={values.salutation}
                    type={'text'}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    label={t('persons.personTitle')}
                    onChange={handleChange}
                    name={'title'}
                    value={values.title}
                    type={'text'}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingBottom='s' spacingTop='s'>
                <GridColumn width={6}>
                  <Input
                    error={errors.firstName}
                    required
                    label={t('common.firstName')}
                    onChange={handleChange}
                    name={'firstName'}
                    value={values.firstName}
                    type={'text'}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Input
                    error={errors.prefixName}
                    label={t('common.prefixName')}
                    onChange={handleChange}
                    name={'prefixName'}
                    value={values.prefixName}
                    type={'text'}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingBottom='s' spacingTop='s'>
                <GridColumn width={6}>
                  <Input
                    error={errors.lastName}
                    required
                    label={t('common.lastName')}
                    onChange={handleChange}
                    name={'lastName'}
                    value={values.lastName}
                    type={'text'}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <DatePicker
                    label={t('common.birthday')}
                    onChange={(e) => {
                      setFieldValue('birthday', e?.toISOString());
                    }}
                    value={values.birthday ? new Date(values.birthday) : undefined}
                    name={'birthday'}
                    showMonthYearDropdown
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingBottom='s' spacingTop='s'>
                <GridColumn width={!data || data?.userStatus === UserStatus.None ? 9 : 12}>
                  <Input
                    error={errors.email}
                    label={t('common.email')}
                    onChange={handleChange}
                    name={'email'}
                    value={values.email}
                    type={'email'}
                  />
                </GridColumn>
                {(!data || data?.userStatus === UserStatus.None) && (
                  <GridColumn width={3}>
                    <Checkbox
                      name={'invite'}
                      onChange={handleChange}
                      checked={values.invite}
                      label={t('profiles.sendInvitation')}
                      className='mt-3'
                    />
                  </GridColumn>
                )}
              </GridRow>
            </Grid>

            <ModalBottomButtons closeButton={{ callback: onClose }} isLoading={isSubmitting} errors={errors} />
          </Form>
        );
      }}
    </Formik>
  );
};
