import { ColorOption, GroupedColorOption } from '@bp/ui-components';
import colors from '../assets/colors/Colors.json';
import { t } from 'i18next';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';

export type UsedColors = {
  color?: string | null | undefined;
  owner?: BpClassType;
};

type ColorPickerOptionsType = {
  htmlColorsInUse?: Array<UsedColors | null>;
  currentColor?: string;
  currentColorUsedBy?: string;
  forTypename: 'Class' | 'Person' | 'Subject' | 'SubjectContainer' | 'Label';
};

type ColorPickerOptionsReturnType = {
  groupedColorOptions: GroupedColorOption[];
  currentColor?: ColorOption;
};

interface ColorOptionWithType extends ColorOption {
  type?: 'Class' | 'Person' | 'Subject' | 'SubjectContainer';
}

function colorPickerOptions({
  htmlColorsInUse,
  currentColor,
  currentColorUsedBy,
  forTypename,
}: ColorPickerOptionsType): ColorPickerOptionsReturnType {
  const allColors: ColorOptionWithType[] = [...colors];
  const usedColors = allColors
    .map((color) => {
      const usedColor = htmlColorsInUse?.find((colorInUse) => {
        return colorInUse?.color === color.html;
      });
      if (usedColor) {
        color.usedBy = [usedColor.owner?.name ?? ''];
        color.type = 'Class';
      }
      return color;
    })
    .filter((c) => c.usedBy && c.type === forTypename);

  const current = usedColors.find((uc) => {
    return uc.html === currentColor;
  });

  if (current) {
    current.usedBy = [currentColorUsedBy ?? ''];
  }

  const options: GroupedColorOption[] = [
    {
      label: t('timetable.freeColors'),
      options: allColors.filter((c) => !c.usedBy),
      isUsed: false,
    },
    {
      label: t('timetable.usedColors'),
      options: usedColors,
      isUsed: true,
    },
  ];

  return {
    groupedColorOptions: options,
    currentColor: current,
  };
}

function getColorName(hexColor: string): string {
  let name: string = t('colors.notSpecified');
  colors.forEach((color) => {
    if (color.html === hexColor) name = color.label;
  });
  return name;
}

export { colorPickerOptions, getColorName };
