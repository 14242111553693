import { useNetworkState } from '@uidotdev/usehooks';
import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from '@bp/ui-components';
import styles from './App.module.scss';
import { useMatrixClient } from './hooks/matrix/useMatrixClient';
import { useTranslation } from 'react-i18next';

const INITIAL_DELAY = 5000;  // 5 seconds
const MIN_DELAY = 2000;      // 2 seconds
const MAX_DELAY = 30000;     // 30 seconds
const REQUEST_TIMEOUT = 3000; // 3 seconds
const MIN_CHECK_INTERVAL = 1000; // 1 second

export const RequireBackend: FC<PropsWithChildren> = ({ children }) => {
  const network = useNetworkState();
  const { t } = useTranslation();
  const matrix = useMatrixClient();

  const abortControllerRef = useRef<AbortController | null>(null);
  const lastCheckTimeRef = useRef<number>(0);
  const failureCountRef = useRef<number>(0);
  const isInitialCheckRef = useRef<boolean>(true);

  const [backendOnline, setBackendOnline] = useState(true);
  const [modal, setModal] = useState(false);
  const [delay, setDelay] = useState(INITIAL_DELAY);

  // Calculate next delay using exponential backoff with jitter
  const calculateNextDelay = useCallback((failures: number): number => {
    if (failures === 0) return INITIAL_DELAY;
    
    // Exponential backoff: MIN_DELAY * 2^failures
    const exponentialDelay = MIN_DELAY * Math.pow(2, failures - 1);
    // Cap at MAX_DELAY
    const cappedDelay = Math.min(exponentialDelay, MAX_DELAY);
    // Add jitter: ±10% of the delay to prevent thundering herd
    const jitter = cappedDelay * 0.1 * (Math.random() * 2 - 1);
    
    return Math.max(MIN_DELAY, Math.min(MAX_DELAY, cappedDelay + jitter));
  }, []);

  const handleSuccess = useCallback(() => {
    failureCountRef.current = 0;
    setBackendOnline(true);
    setDelay(INITIAL_DELAY);
    setModal(false);
    
    if (matrix && !matrix.clientRunning && network.online) {
      matrix.startClient();
    }
  }, [matrix, network.online]);

  const handleFailure = useCallback(() => {
    failureCountRef.current++;
    setBackendOnline(false);
    setModal(true);
    
    if (matrix?.clientRunning) {
      matrix.stopClient();
    }

    const nextDelay = calculateNextDelay(failureCountRef.current);
    setDelay(nextDelay);
  }, [matrix, calculateNextDelay]);

  const fetchHealth = useCallback(async () => {
    // Skip health check if browser is offline
    if (!network.online) {
      handleFailure();
      return;
    }

    // Prevent too frequent checks, except for the initial check
    const now = Date.now();
    if (!isInitialCheckRef.current && now - lastCheckTimeRef.current < MIN_CHECK_INTERVAL) {
      return;
    }
    
    isInitialCheckRef.current = false;
    lastCheckTimeRef.current = now;

    // Cancel any ongoing check
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    try {
      abortControllerRef.current = new AbortController();
      
      // Use Promise.race for timeout
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Timeout')), REQUEST_TIMEOUT);
      });

      const fetchPromise = fetch(import.meta.env.VITE_APP_BACKEND_URI + '/_health', {
        signal: abortControllerRef.current.signal,
        headers: {
          'Cache-Control': 'no-cache, no-store',
          'Pragma': 'no-cache',
          'If-None-Match': '', // Prevent 304 responses
          'If-Modified-Since': ''
        }
      });

      const resp = await Promise.race([fetchPromise, timeoutPromise]) as Response;

      if (!resp.ok) {
        throw new Error('Health check failed');
      }

      handleSuccess();
    } catch (error) {
      // Don't update state if the request was aborted
      if (error instanceof Error && error.name === 'AbortError') {
        return;
      }

      handleFailure();
    } finally {
      abortControllerRef.current = null;
    }
  }, [network.online, handleSuccess, handleFailure]);

  // Listen to browser online/offline and visibility events
  useEffect(() => {
    const handleOnline = () => {
      isInitialCheckRef.current = true; // Treat as initial check when coming back online
      void fetchHealth();
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        isInitialCheckRef.current = true; // Treat as initial check when tab becomes visible
        void fetchHealth();
      }
    };

    const handleFocus = () => {
      isInitialCheckRef.current = true; // Treat as initial check when window gains focus
      void fetchHealth();
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('focus', handleFocus);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial check on mount
    void fetchHealth();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('focus', handleFocus);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [fetchHealth]);

  // Regular health check interval
  useEffect(() => {
    const id = setInterval(() => void fetchHealth(), delay);
    return () => {
      clearInterval(id);
      // Cleanup any pending request on unmount
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [fetchHealth, delay]);

  return (
    <>
      {children}
      {modal && (
        <Modal
          isOpen={modal}
          onRequestClose={() => {
            setModal(false);
          }}
          title={t('offline.connectionIssues')}
        >
          <div className={styles['offline-container']}>
            <div className={styles.message}>
              {!network.online && <div>{t('offline.clientOffline')}</div>}
              {!backendOnline && <div>{t('offline.serverOffline')}</div>}
              <div>{t('offline.autoClose')}</div>
              <div>{t('offline.unsavedData')}</div>
            </div>
            <div className={styles.status}>
              <div>
                <div>Server</div>
                <span className={backendOnline ? styles.online : styles.offline}>
                  {backendOnline ? 'Online' : 'Offline'}
                </span>
              </div>
              <div>
                <div>Client</div>
                <span className={network.online ? styles.online : styles.offline}>
                  {network.online ? 'Online' : 'Offline'}
                </span>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
