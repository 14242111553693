import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon, Grid, GridRow } from '@bp/ui-components';
import { useBpOrganizationQuery, useUpdateOrganizationMutation } from '../../../client/bp-graphql-client-defs';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { BpCard } from '../../../components/BpCard/BpCard';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { BpTipTap } from '../../../components/BpTipTap/BpTipTap';
import { BpTipTapText } from '../../../components/BpTipTapText/BpTipTapText';
import { niceDate } from 'utils/dateCalculations';
import { OrganizationConnectionEdges } from '../../Settings/subpages/types';

export type LicensedProductThreema = {
  __typename?: 'Threema';
  uuid?: string;
  apiToken?: string;
  organizationConnection?: OrganizationConnectionEdges;
};

export type LicensedProductNextcloud = {
  __typename?: 'Nextcloud';
  uuid?: string;
  serverUri?: string;
  serverAliase?: string;
  adminUser?: string;
  adminPass?: string;
  organizationConnection?: OrganizationConnectionEdges;
};

export type LicensedProduct =
  | {
      __typename?: 'BigBlueButton';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | LicensedProductNextcloud
  | LicensedProductThreema
  | {
      __typename?: 'ThreemaBroadcast';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | {
      __typename?: 'Zoom';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | {
      __typename?: 'Bildungsplattform';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    };

export const InstitutionOverviewSubpage = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const perms = usePermissionChecker();

  const [{ data }] = useBpOrganizationQuery({ variables: { where: { uuid: organizationUuid } } });
  const [, updateOrganization] = useUpdateOrganizationMutation();

  const [updating, setUpdating] = useState<boolean>(false);
  const [aboutInfo, setAboutInfo] = useState<string>('');

  async function saveAboutInfo() {
    setUpdating(true);
    await updateOrganization({
      where: { uuid: organizationUuid },
      update: { aboutInfo: aboutInfo, aboutInfoLastUpdated: new Date() },
    });
    setUpdating(false);
  }

  const organization = data?.organizations.find((o) => (o.uuid = organizationUuid));
  const canEditAboutInfo = perms?.isOrganizationAdmin(pimAuthClaims.getOrganization());

  return (
    <BpSubpage>
      <Grid>
        <GridRow>
          <BpCard
            header={{
              headline: t('institution.administration'),
              actions: canEditAboutInfo
                ? [{ text: t('common.save'), icon: <CheckIcon />, callback: saveAboutInfo, disabled: updating }]
                : undefined,
              subHeadline: organization?.aboutInfoLastUpdated
                ? t('common.lastUpdated', {
                    updated: niceDate(organization?.aboutInfoLastUpdated, 'medium', 'short'),
                  })
                : undefined,
            }}
          >
            {canEditAboutInfo ? (
              <div>
                <BpTipTap loading={updating} onChange={setAboutInfo} defaultValue={organization?.aboutInfo ?? ''} />
              </div>
            ) : (
              <BpTipTapText content={organization?.aboutInfo ?? ''} />
            )}
          </BpCard>
        </GridRow>
      </Grid>
    </BpSubpage>
  );
};
