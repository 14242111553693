import { EmptyState, DivisionIllustration, Tooltip } from '@bp/ui-components';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import styles from './DivisionsList.module.scss';
import { useDivisionsQuery, useClassGroupsQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';

type DivisionsListProps = {
  bpClass: BpClassType;
};

type DivisionsListType = {
  uuid: string;
  name: string;
  groups: {
    uuid: string;
    name: string;
    shortName: string;
  }[];
};

export const DivisionsList = ({ bpClass }: DivisionsListProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const divisionsContext = useMemoizedCacheTag('DIVISION');
  const classGroupsContext = useMemoizedCacheTag('CLASSGROUP');

  const [{ data: divisionsData }] = useDivisionsQuery({
    variables: { where: { class: { uuid: bpClass.uuid }, organization: { uuid: organizationUuid } } },
    context: divisionsContext,
  });

  const [{ data: groupsData }] = useClassGroupsQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: classGroupsContext,
  });

  const divisions: DivisionsListType[] =
    divisionsData?.divisions.map((division) => {
      return {
        uuid: division.uuid,
        name: division.name,
        groups:
          groupsData?.classGroups
            .filter((g) => g.division.uuid === division.uuid)
            .map((g) => {
              return {
                uuid: g.uuid,
                name: g.name,
                shortName: g.shortName,
              };
            }) ?? [],
      };
    }) ?? [];

  return (
    <div className={styles['divisions-list']}>
      {divisions.length === 0 ? (
        <EmptyState
          borderRadius='none'
          size='small'
          icon={<DivisionIllustration />}
          title={t('divisions.noDivisions')}
        />
      ) : (
        divisions.map((division) => {
          return (
            <div key={division.uuid} className={styles['list-item']}>
              <div className={styles.groups}>
                {division.groups.map((group) => {
                  return (
                    <div
                      key={group.uuid}
                      className={styles.group}
                      style={{ flexBasis: `${100 / division.groups.length}%` }}
                    >
                      <Tooltip content={group.name} triggerClass={styles.name}>
                        {group.shortName}
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
              <div className={styles['group-name']}>{division.name}</div>
            </div>
          );
        })
      )}
    </div>
  );
};
