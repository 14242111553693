import { Modal } from '@bp/ui-components';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import { ClassGroupProfilesForm } from '../forms/ClassGroupProfilesForm';
import { useState } from 'react';

export type lassGroupProfilesModalProps = {
  bpClass: BpClassType;
  isOpen: boolean;
  onClose: () => void;
};

export const ClassGroupProfilesModal = ({ bpClass, isOpen, onClose }: lassGroupProfilesModalProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal loading={loading} title={t('classes.assignGroupProfiles')} isOpen={isOpen} onRequestClose={onClose}>
      <ClassGroupProfilesForm bpClass={bpClass} onLoad={setLoading} onClose={onClose} />
    </Modal>
  );
};
