import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { MultiValue } from 'react-select';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { AdminRoles, BpAdminRoles, ProfileRoles } from '@bp/pim-auth-constants';
import { useMemo } from 'react';
import { adminRolesAsString } from '../../utils/roleAsString';
import { useBpUpdateProfileRolesMutation } from '../../client/bp-graphql-client-defs';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { ProfileTableData } from '../../pages/Institution/subpages/InstitutionPersonsSubpage';

type GrantRolesFormProps = { profile: ProfileTableData | null; setModalClosed: (close: boolean) => void };

type GrantRolesValued = {
  uuid: string;
  roles: Array<string>;
};

export const GrantRolesForm = ({ profile, setModalClosed }: GrantRolesFormProps) => {
  const { t } = useTranslation();
  const [, updateProfileRoles] = useBpUpdateProfileRolesMutation();
  const context = useMemoizedCacheTag('PROFILE');

  const formikValues: GrantRolesValued = {
    uuid: profile?.uuid ?? '',
    roles: profile?.organizationRoles.filter((r) => r !== null) ?? [],
  };

  const profileRole = profile?.organizationRoles
    .filter((r) => Object.values(ProfileRoles).includes(r as ProfileRoles))
    .shift();

  const handleSubmit = async (values: GrantRolesValued) => {
    if (profileRole) values.roles.push(profileRole);
    const resp = await updateProfileRoles(values, context);

    resp.error ? showErrorToast(resp.error) : showSuccessToast(t('common.saved'));
    setModalClosed(false);
  };

  const adminSelectOpts = useMemo(() => {
    return [
      AdminRoles.ProfileAdmin,
      AdminRoles.CollaborationAdmin,
      AdminRoles.OrganizationAdmin,
      AdminRoles.GroupAdmin,
      AdminRoles.ShadowAdmin,
      BpAdminRoles.CloudAdmin,
      BpAdminRoles.ThreemaAdmin,
      BpAdminRoles.ZoomAdmin,
    ].map((r) => ({ label: adminRolesAsString(r), value: r }) as SelectOptionType);
  }, []);

  return (
    <Formik onSubmit={handleSubmit} initialValues={formikValues}>
      {({ resetForm, isSubmitting, errors, setFieldValue, values }) => {
        const onClose = () => {
          resetForm();
          setModalClosed(false);
        };

        return (
          <Form>
            <Select
              label={t('adminRoles.title')}
              value={[...adminSelectOpts.filter((v) => values.roles.includes(v.value as string))]}
              options={adminSelectOpts}
              onChange={async (event) => {
                await setFieldValue(
                  'roles',
                  (event as MultiValue<SelectOptionType>).map((e) => e.value),
                );
              }}
              name={'roles'}
              isMulti={true}
              isSearchable={true}
            />

            <ModalBottomButtons
              isLoading={isSubmitting}
              closeButton={{
                callback: () => {
                  resetForm();
                  onClose();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
