import { ClassUpdateInput } from '@bp/bp-graphql-types';
import {
  ColorPicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import {
  ClassCreateInput,
  ClassTutorsConnectFieldInput,
  CreateClassDocument,
  InputMaybe,
  UpdateClassDocument,
  useBpMinimalProfileQuery,
} from 'client/bp-graphql-client-defs';
import { Form, Formik, FormikHelpers } from 'formik';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useCreateSelectOptions } from 'hooks/useCreateSelectOptions';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CombinedError, useMutation } from 'urql';
import { colorPickerOptions, UsedColors } from 'utils/colorHelper';
import { getGradeOptions } from 'utils/gradesHelper';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { OrganizationConfigContext } from '../../../context/OrganizationConfigContext';

type ClassesFormProps = {
  selectedClass?: BpClassType;
  colorsInUse: (UsedColors | null)[];
  onClose: () => void;
};

type Tutors = {
  one: string | null;
  two: string | null;
  three: string | null;
};

export const ClassForm = ({ selectedClass, colorsInUse, onClose }: ClassesFormProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const { currentSchoolYear } = useContext(OrganizationConfigContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [tutorUuids, setTutorUuids] = useState<Tutors>({ one: null, two: null, three: null });

  const profileContext = useMemoizedCacheTag('PROFILE');
  const classesContext = useMemoizedCacheTag('CLASS');
  const [, create] = useMutation(CreateClassDocument);
  const [, update] = useMutation(UpdateClassDocument);

  const initialValues: BpClassType = {
    uuid: selectedClass?.uuid ?? '',
    name: selectedClass?.name ?? '',
    shortName: selectedClass?.shortName ?? '',
    grade: selectedClass?.grade ?? 0,
    gradeGroup: selectedClass?.gradeGroup ?? '',
    tutor1Uuid: selectedClass?.tutor1Uuid ?? '',
    tutor2Uuid: selectedClass?.tutor2Uuid ?? '',
    tutor3Uuid: selectedClass?.tutor3Uuid ?? '',
    color: {
      color: selectedClass?.color.color ?? '',
      colorLabel: selectedClass?.color.colorLabel ?? '',
    },
    source: selectedClass?.uuid ?? '',
    divisionUuids: selectedClass?.divisionUuids ?? [],
    profileUuids: selectedClass?.profileUuids ?? [],
  };

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: profileContext,
  });

  const teachers = profileData?.profiles.filter((p) => p.organizationRoles.includes('TEACHER'));

  const teacherOptions = useCreateSelectOptions(teachers, 'uuid', 'displayName');
  const gradeOptions = getGradeOptions();

  const uniqueTeacherOptions: SelectOptionType[] = useMemo(() => {
    let unique = teachers;
    if (tutorUuids.one) unique = unique?.filter((t) => t.uuid !== tutorUuids.one);
    if (tutorUuids.two) unique = unique?.filter((t) => t.uuid !== tutorUuids.two);
    if (tutorUuids.three) unique = unique?.filter((t) => t.uuid !== tutorUuids.three);
    return (
      unique?.map((t) => {
        return { value: t.uuid, label: t.displayName ?? '' };
      }) ?? []
    );
  }, [teachers, tutorUuids.one, tutorUuids.two, tutorUuids.three]);

  const colorPickerData = colorPickerOptions({
    htmlColorsInUse: colorsInUse,
    currentColor: selectedClass?.color.color ?? '',
    currentColorUsedBy: selectedClass?.name,
    forTypename: 'Class',
  });

  const handleSubmit = async (values: BpClassType, formHelpers: FormikHelpers<BpClassType>) => {
    let hasError = false;
    if (!values.name) {
      formHelpers.setFieldError('name', t('validation.required.designation'));
      hasError = true;
    }
    if (!values.grade) {
      formHelpers.setFieldError('grade', t('validation.required.grade'));
      hasError = true;
    }
    if (!values.shortName) {
      formHelpers.setFieldError('shortName', t('validation.required.shortName'));
      hasError = true;
    }
    if (hasError) {
      return;
    }

    let result: { error?: CombinedError | undefined };
    setLoading(true);

    const tutors: InputMaybe<Array<ClassTutorsConnectFieldInput>> = [];
    if (values.tutor1Uuid) {
      tutors.push({
        edge: {
          order: 1,
        },
        where: {
          node: {
            uuid: values.tutor1Uuid,
          },
        },
      });
    }
    if (values.tutor2Uuid) {
      tutors.push({
        edge: {
          order: 2,
        },
        where: {
          node: {
            uuid: values.tutor2Uuid,
          },
        },
      });
    }
    if (values.tutor3Uuid) {
      tutors.push({
        edge: {
          order: 3,
        },
        where: {
          node: {
            uuid: values.tutor3Uuid,
          },
        },
      });
    }

    const now = new Date().toISOString();
    if (!selectedClass) {
      const data: ClassCreateInput = {
        createdAt: now,
        updatedAt: now,
        name: values.name,
        grade: values.grade.toString(),
        gradeGroup: values.gradeGroup,
        shortName: values.shortName,
        color: values.color.color,
        managedBy: 'bp',
        tutors: { connect: tutors },
      };
      
      result = await create(
        {
          input: {
            ...data,
            schoolYear: { connect: { where: { node: { uuid: currentSchoolYear.uuid } } } },
            foreignRefId: [],
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: organizationUuid,
                  },
                },
              },
            },
          },
        },
        classesContext,
      );
    } else {
      const data: ClassUpdateInput = {
        name: values.name,
        grade: values.grade.toString(),
        gradeGroup: values.gradeGroup,
        color: values.color.color,

        tutors: [
          {
            disconnect: [{}],
            connect: tutors,
          },
        ],
        updatedAt: now,
      };

      result = await update(
        {
          where: {
            uuid: selectedClass.uuid,
          },
          update: data,
        },
        classesContext,
      );
    }

    if (!result || result.error) {
      showErrorToast({
        name: 'Error',
        message: 'mutationResult.error',
        graphQLErrors: [],
      });
    } else {
      if (selectedClass) {
        showSuccessToast(t('classes.classEdited'));
      } else {
        showSuccessToast(t('classes.classCreated'));
      }
    }

    setLoading(false);
    formHelpers.resetForm();
    onClose();
  };

  useEffect(() => {
    const tutors: Tutors = {
      one: selectedClass?.tutor1Uuid ?? null,
      two: selectedClass?.tutor2Uuid ?? null,
      three: selectedClass?.tutor3Uuid ?? null,
    };
    setTutorUuids(tutors);
  }, [selectedClass?.tutor1Uuid, selectedClass?.tutor2Uuid, selectedClass?.tutor3Uuid]);

  return (
    <Formik<BpClassType>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {({ errors, values, setFieldValue, isSubmitting, dirty, isValidating, handleSubmit }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <GridColumn width={10}>
                  <Input
                    name='name'
                    value={values.name}
                    label={t('common.designation')}
                    onChange={async (e) => await setFieldValue('name', e.target.value)}
                    required
                    error={errors.name as string}
                  />
                </GridColumn>
                <GridColumn width={2}>
                  <ColorPicker
                    name='color'
                    label={t('common.color')}
                    options={colorPickerData.groupedColorOptions}
                    maxMenuHeight={300}
                    menuPosition='fixed'
                    onChange={async (option) => {
                      await setFieldValue('color.color', option?.html);
                    }}
                    defaultValue={colorPickerData.currentColor}
                    error={errors.color as string}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s' spacingBottom='s'>
                <GridColumn width={4}>
                  <Select
                    options={gradeOptions}
                    onChange={async (e) => {
                      const grade = e && ((e as SelectOptionType).value as number);
                      await setFieldValue('grade', grade);
                    }}
                    value={gradeOptions.find((g) => g.value === String(values.grade))}
                    name='grade'
                    label={t('classes.grade')}
                    menuPosition='fixed'
                    required
                    error={errors.grade as string}
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Input
                    name='gradeGroup'
                    value={values.gradeGroup}
                    label={t('classes.gradeGroup')}
                    onChange={async (e) => await setFieldValue('gradeGroup', e.target.value)}
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Input
                    name='shortNmae'
                    value={values.shortName}
                    label={t('classes.shortName')}
                    onChange={async (e) => await setFieldValue('shortName', e.target.value)}
                    required
                    error={errors.shortName as string}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s'>
                <GridColumn width={4}>
                  <Select
                    options={uniqueTeacherOptions}
                    onChange={async (e) => {
                      const uuid = e && ((e as SelectOptionType).value as string);
                      if (uuid) {
                        setTutorUuids({ ...tutorUuids, one: uuid });
                      } else {
                        setTutorUuids({ one: null, two: null, three: null });
                      }
                      await setFieldValue('tutor1Uuid', uuid);
                    }}
                    value={teacherOptions.find((t) => t.value === values.tutor1Uuid)}
                    name='tutor1'
                    label={t('classes.tutor') + ' 1'}
                    menuPosition='fixed'
                    isClearable
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Select
                    options={uniqueTeacherOptions}
                    onChange={async (e) => {
                      const uuid = e && ((e as SelectOptionType).value as string);
                      if (uuid) {
                        setTutorUuids({ ...tutorUuids, two: uuid });
                      } else {
                        setTutorUuids({ ...tutorUuids, two: null, three: null });
                      }
                      await setFieldValue('tutor2Uuid', uuid);
                    }}
                    value={teacherOptions.find((t) => t.value === values.tutor2Uuid)}
                    name='tutor2'
                    label={t('classes.tutor') + ' 2'}
                    menuPosition='fixed'
                    isClearable
                    disabled={!tutorUuids.one}
                  />
                </GridColumn>
                <GridColumn width={4}>
                  <Select
                    options={uniqueTeacherOptions}
                    onChange={async (e) => {
                      const uuid = e && ((e as SelectOptionType).value as string);
                      if (uuid) {
                        setTutorUuids({ ...tutorUuids, three: uuid });
                      } else {
                        setTutorUuids({ ...tutorUuids, three: null });
                      }
                      await setFieldValue('tutor3Uuid', uuid);
                    }}
                    value={teacherOptions.find((t) => t.value === values.tutor3Uuid)}
                    name='tutor3'
                    label={t('classes.tutor') + ' 3'}
                    menuPosition='fixed'
                    isClearable
                    disabled={!tutorUuids.two}
                  />
                </GridColumn>
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: isSubmitting || !dirty || isValidating,
                callback: () => {
                  handleSubmit();
                },
              }}
              isLoading={loading}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
