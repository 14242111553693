import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import styles from './ClassesListItem.module.scss';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Tooltip,
} from '@bp/ui-components';
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useBpMinimalProfileQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { DivisionsList } from 'components/Divisions/DivisionsList';

type ClassesListItemProps = {
  bpClass: BpClassType;
  onEditClass: (uuid: string) => void;
  onAssignClassProfiles: (uuid: string) => void;
  onEditDivisions: (uuid: string) => void;
  onAssignGroupProfiles: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

export const ClassesListItem = ({
  bpClass,
  onEditClass,
  onAssignClassProfiles,
  onEditDivisions,
  onAssignGroupProfiles,
  onDelete,
}: ClassesListItemProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();
  const profileContext = useMemoizedCacheTag('PROFILE');

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [bodyHeight, setBodyHeight] = useState<number>(0);

  const bodyRef = useRef<HTMLDivElement>(null);

  const menu: DropdownMenuItem[] = [
    {
      label: t('classes.editClass'),
      onClick: () => onEditClass(bpClass.uuid),
    },
    {
      label: t('classes.editDivisions'),
      onClick: () => onEditDivisions(bpClass.uuid),
    },
    {
      label: t('classes.assignClassProfiles'),
      onClick: () => onAssignClassProfiles(bpClass.uuid),
    },
    {
      label: t('classes.assignGroupProfiles'),
      onClick: () => onAssignGroupProfiles(bpClass.uuid),
    },
    { type: 'ruler' },
    {
      type: 'component',
      color: 'error',
      onClick: () => bpClass.divisionUuids.length === 0 && onDelete(bpClass.uuid),
      disabled: bpClass.divisionUuids.length > 0,
      node:
        bpClass.divisionUuids.length > 0 ? (
          <Tooltip triggerStyle={{ width: '100%' }} content={t('classes.deleteDivisionsFirst')}>
            {t('classes.deleteClass')}
          </Tooltip>
        ) : (
          t('classes.deleteClass')
        ),
    },
  ];

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: profileContext,
  });

  const teachers = profileData?.profiles.filter((p) => p.organizationRoles.includes('TEACHER'));
  const tutor1 = teachers?.find((t) => t.uuid === bpClass.tutor1Uuid) ?? null;
  const tutor2 = teachers?.find((t) => t.uuid === bpClass.tutor2Uuid) ?? null;

  const studentCount = bpClass.profileUuids.length;

  useEffect(() => {
    setBodyHeight(isExpanded && bodyRef.current ? bodyRef.current.scrollHeight : 0);
  }, [isExpanded]);

  const classes = classNames(styles['classes-list-item'], { [styles.expanded]: isExpanded });

  return (
    <div className={classes} style={{ height: `calc(${bodyHeight}px + var(--spacing-8))` }}>
      <div className={styles.header}>
        <div className={styles.info}>
          <div className={styles.name}>{bpClass.name}</div>
          <Tooltip content={bpClass.color.colorLabel}>
            <div className={styles['color-dot']} style={{ backgroundColor: bpClass.color.color ?? '#fff' }}></div>
          </Tooltip>
          <div className={styles.count}>({t('classes.studentCount', { count: studentCount })})</div>
        </div>
        <div className={styles.actions}>
          <Dropdown className='ml-2' trigger={<Button hierarchy='ghost' icon={<DotsHorizontalIcon />} />}>
            <DropdownMenu data={menu} />
          </Dropdown>
          <Button
            className='ml-2'
            hierarchy='ghost'
            icon={isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
            onClick={() => setIsExpanded((e) => !e)}
          />
        </div>
      </div>
      <div ref={bodyRef} className={styles.body}>
        <div className={styles['info-wrapper']}>
          <div className={styles.info}>
            <div>{bpClass.grade}</div>
            <div>{t('classes.grade')}</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.gradeGroup !== '' ? bpClass.gradeGroup : '---'}</div>
            <div>{t('classes.gradeGroup')}</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.shortName}</div>
            <div>{t('classes.shortName')}</div>
          </div>
          <div className={styles.info}>
            <div>{bpClass.source === 'bp' ? t('app.title.full') : bpClass.source}</div>
            <div>{t('classes.source')}</div>
          </div>
          <div className={styles.info}>
            <div>{tutor1?.displayName ?? '---'}</div>
            <div>{t('classes.tutor')} 1</div>
          </div>
          <div className={styles.info}>
            <div>{tutor2?.displayName ?? '---'}</div>
            <div>{t('classes.tutor')} 2</div>
          </div>
        </div>
        <div
          className={classNames(styles['divisions-wrapper'], { [styles.empty]: bpClass.divisionUuids.length === 0 })}
        >
          <DivisionsList bpClass={bpClass} />
        </div>
      </div>
    </div>
  );
};
