import { GridRow, LazyLoader, showToast, useDefaultSelecting } from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import { ProcuratGroupsTable } from './ProcuratGroupsTable';
import { useProcuratGroups } from './hooks/useProcuratGroups';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useGroupAsCourseQuery } from '../../client/bp-graphql-client-defs';
import { backendApi } from '../../utils/backendApi';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';

export const ProcuratGroups = () => {
  const { organization } = useContext(OrganizationConfigContext);
  const { t } = useTranslation();
  const { groups, isFetching } = useProcuratGroups();
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const tableData = groups?.personGroups ?? [];
  const groupsContext = useMemoizedCacheTag('GROUP');
  const [{ data: groupsData }, reloadBpGroups] = useGroupAsCourseQuery({
    variables: { where: { organization: { uuid: organization?.uuid } } },
    context: groupsContext,
  });

  const [importing, setImporting] = useState(false);

  const handleImport = async () => {
    showToast(t('procuratImport.importStarted'), {
      type: 'info',
      theme: 'colored',
    });

    setImporting(true);
    const selectedGroups = tableData.filter((_, index) => Object.keys(rowSelection).includes(index.toString()));
    const importData = selectedGroups.map((group) => ({ id: group.id }));

    const resp = await backendApi.put('/procurat/groups/import', JSON.stringify(importData));

    if (resp.ok) {
      reloadBpGroups({ requestPolicy: 'network-only' });
      showSuccessToast(t('procuratImport.importSuccess'));
      onRowSelectionChange({});
    }

    if (!resp.ok) {
      showErrorToast({ graphQLErrors: [], name: '', message: resp.statusText });
    }
    setImporting(false);
  };

  return (
    <GridRow>
      <BpCard header={{ headline: t('groups.title') }}>
        {(isFetching || importing) && <LazyLoader embedded transparent />}

        {!isFetching && !importing && (
          <ProcuratGroupsTable
            data={tableData}
            groupsData={groupsData}
            rowSelection={rowSelection}
            onRowSelectionChange={onRowSelectionChange}
            handleImport={handleImport}
          />
        )}
      </BpCard>
    </GridRow>
  );
};
