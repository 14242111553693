import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button } from '@bp/ui-components';
import styles from './App.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { PageLayoutPlaceholder } from './components/PageLayoutPlaceholder/PageLayoutPlaceholder';
import { App as CapApp } from '@capacitor/app';
import { bpUserManager } from './BpAuthProvider';
import { Browser } from '@capacitor/browser';
import { updateClaims } from './utils/authStore';

type RequireAuthProps = {
  children?: React.ReactNode;
};

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const [hasTriedSigning, setHasTriedSigning] = useState(false);
  const [isUserManagerReady, setIsUserManagerReady] = useState(false);
  const auth = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Check if UserManager is properly initialized and synced with session
  useEffect(() => {
    const checkUserManager = async () => {
      const userManager = bpUserManager.getUserManager();
      if (!userManager) {
        return;
      }

      try {
        const user = await userManager.getUser();
        if (!user && auth.isAuthenticated) {
          // We have auth data but UserManager isn't aware, try to sync
          await userManager.signinSilent();
        }
        setIsUserManagerReady(true);
      } catch (error) {
        console.error('Error syncing UserManager:', error);
        // If silent sign-in fails, we'll fall back to regular auth flow
        setIsUserManagerReady(true);
      }
    };

    if (auth.isAuthenticated && !isUserManagerReady) {
      void checkUserManager();
    }
  }, [auth.isAuthenticated, isUserManagerReady]);

  useEffect(() => {
    // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
    CapApp.addListener('appUrlOpen', async ({ url }) => {
      if (url.includes('state') && (url.includes('code') || url.includes('error'))) {
        const user = await bpUserManager.getUserManager().signinCallback(url);
        await bpUserManager.getUserManager().storeUser(user ?? null);
        Browser.close();
      }
    });
  }, [auth]);

  const params = new URLSearchParams(location.search);
  const accessDenied = params.get('error') === 'access_denied';

  useEffect(() => {
    if (auth.isAuthenticated && isUserManagerReady) {
      const returnUrl = sessionStorage.getItem('returnUrl');
      if (returnUrl && returnUrl !== '/login') {
        sessionStorage.removeItem('returnUrl');
        navigate(returnUrl);
      }
    }
  }, [auth.isAuthenticated, isUserManagerReady, navigate]);

  // Store returnUrl when component mounts if we're on a protected route
  useEffect(() => {
    if (location.pathname !== '/login' && location.pathname !== '/') {
      sessionStorage.setItem('returnUrl', location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSigning) {
      const returnUrl = location.pathname + location.search;
      sessionStorage.setItem('returnUrl', returnUrl);
      void auth.signinRedirect({});
      setHasTriedSigning(true);
    }
  }, [auth, hasTriedSigning, location]);

  // TODO: find reasonable interval
  const autoUpdateInterval = 10_000;
  const autoUpdateClaims = useCallback(() => {
    if (auth.isAuthenticated) {
      void updateClaims();
    }
  }, [auth]);

  useEffect(() => {
    const id = setInterval(() => autoUpdateClaims(), autoUpdateInterval);
    return () => clearInterval(id);
  }, [autoUpdateClaims, autoUpdateInterval]);

  if (auth.isLoading || (auth.isAuthenticated && !isUserManagerReady)) {
    return (
      <PageLayoutPlaceholder>
        <div>Loading...</div>
      </PageLayoutPlaceholder>
    );
  }

  if (auth.error) {
    return (
      <PageLayoutPlaceholder>
        <div>
          {auth.error.message} ({auth.error.name})
        </div>
        <div className={styles.vspace}>
          <Button hierarchy='primary' onClick={() => auth.signinRedirect()} fullWidth={true}>
            {t('auth.login')}
          </Button>
        </div>
      </PageLayoutPlaceholder>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <PageLayoutPlaceholder>
        {!accessDenied && (
          <div className={styles.vspace}>
            <Button hierarchy='primary' onClick={() => auth.signinRedirect()} fullWidth={true}>
              {t('auth.login')}
            </Button>
          </div>
        )}
        {accessDenied && (
          <>
            <div className={styles.loginseparator}>{t(params.get('error_description') ?? 'auth.error')}</div>
            <div className={styles.vspace}>
              Leider hat der Benutzer mit dem Sie aktuell in TK-Login angemeldet sind keinen Zugriff auf diese
              Anwendung.
              <br />
              <br />
              Bitte melden Sie sich ab und dann mit einem anderen Benutzer an.
            </div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => auth.signoutRedirect()} fullWidth={true}>
                {t('auth.logout')}
              </Button>
            </div>
          </>
        )}
      </PageLayoutPlaceholder>
    );
  }

  return <>{children}</>;
};
