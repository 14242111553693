import React, { FC, Ref } from 'react';
import styles from './TeachingUnitsTree.module.scss';
import { NodeModel } from '@minoru/react-dnd-treeview';
import {
  ArrowRightIcon,
  Button,
  DotsVerticalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  FileIcon,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { CustomData, TreeItemType } from './types';
import classNames from 'classnames';
import { useConfirm } from 'hooks/useConfirm';

type TeachingUnitsTreeItemProps = {
  node: NodeModel<CustomData>;
  dragHandleRef: Ref<HTMLDivElement> | undefined;
  isOpen: boolean;
  isSelected: boolean;
  childrenCount: number;
  isEditable?: boolean;
  onToggle: (id: NodeModel['id']) => void;
  onSelect: (node: NodeModel<CustomData>) => void;
  onDelete: (id: string) => void;
  onTeachingUnitAdd?: (parentUuid: string | null) => void;
  onTopicAdd?: (parentUuid: string) => void;
  onTopicEdit?: (topicUuid: string) => void;
};

export const TeachingUnitsTreeItem: FC<TeachingUnitsTreeItemProps> = ({
  onTopicAdd,
  onTeachingUnitAdd,
  onTopicEdit,
  isEditable = false,
  isOpen,
  node,
  dragHandleRef,
  onDelete,
  isSelected,
  onSelect,
  onToggle,
  childrenCount,
}) => {
  const { droppable } = node;
  const { t } = useTranslation();

  const { ConfirmationDialog, confirm: confirmDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('teachingUnits.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (node?.data?.type === TreeItemType.topic) {
      onToggle(node.id);
    } else {
      if (!droppable) onSelect(node);
    }
  };

  const ttDropdownItems: DropdownMenuItem[] = [
    {
      label: t('common.createType', { type: t('teachingUnits.titleSingular') }),
      onClick: onTeachingUnitAdd ? () => onTeachingUnitAdd(node.data?.uuid ?? null) : undefined,
    },
    {
      label: t('common.createType', {
        type: t('teachingTopics.titleSingular'),
      }),
      onClick: onTopicAdd ? () => onTopicAdd(node.id as string) : undefined,
    },
    {
      label: t('common.rename'),
      onClick: onTopicEdit ? () => onTopicEdit(node.id as string) : undefined,
    },
    { type: 'ruler' },
    {
      label: t('delete.delete'),
      color: 'error',
      onClick: async () => {
        const res = await confirmDelete();
        if (res) {
          onDelete(node.id as string);
        }
      },
      disabled: childrenCount !== 0,
    },
  ];

  const tuDropdownItems: DropdownMenuItem[] = [
    {
      label: t('delete.delete'),
      color: 'error',
      onClick: async () => {
        const res = await confirmDelete();
        if (res) {
          onDelete(node.id as string);
        }
      },
      disabled: childrenCount !== 0,
    },
  ];

  const classes = classNames(styles['teaching-unit-tree-item'], {
    [styles.active]: isSelected,
    [styles.editable]: isEditable,
  });

  return (
    <>
      <div className={classes} onClick={(e) => handleClick(e)}>
        <div className={styles.icon} ref={dragHandleRef}>
          {node?.data?.type === TreeItemType.topic ? (
            <ArrowRightIcon
              data-cy='collapse-topics'
              className={classNames(styles['expand-icon'], { [styles.open]: isOpen }, 'svg-icon')}
            />
          ) : (
            <FileIcon className={classNames(styles['file-icon'], 'svg-icon')} />
          )}
        </div>
        <div className={styles.title}>
          <div className={styles.text}>{node.text}</div>
        </div>
        <div className={styles.action}>
          {childrenCount > 0 && <div className={styles['child-count']}>{childrenCount}</div>}
          {isEditable && (
            <Dropdown trigger={<Button hierarchy='ghost' icon={<DotsVerticalIcon className='svg-icon small' />} />}>
              <DropdownMenu data={node?.data?.type === TreeItemType.topic ? ttDropdownItems : tuDropdownItems} />
            </Dropdown>
          )}
        </div>
      </div>

      <ConfirmationDialog />
    </>
  );
};
