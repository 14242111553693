import { Form, Formik } from 'formik';
import {
  useBigBlueButtonsQuery,
  useCreateBigBlueButtonsMutation,
  useUpdateBigBlueButtonsMutation,
} from '../../client/bp-graphql-client-defs';
import { Checkbox, DatePicker, Input, Select, SelectOptionType } from '@bp/ui-components';
import { LicensedProduct } from '../../pages/Institution/subpages/InstitutionOverviewSubpage';
import { useTranslation } from 'react-i18next';
import { ensureDate } from '../../utils/dateCalculations';
import { showErrorToast } from '../../utils/showErrorToast';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { ModalBottomButtons } from '../ModalBottomButtons/ModalBottomButtons';
import { SingleValue } from 'react-select';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { validationSchema } from './bigBlueButtonFormSchema';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { bookedBBBSlots } from '../../utils/bookedBBBSlots';

type BBBValuesType = {
  apiUrl?: string | null;
  apiSecret?: string | null;
  allowRecording?: boolean | null;
  version?: number | null;
  notAfter: string;
  notBefore: string;
  maxParticipantsPerMeeting: number | null;
};

type BigBlueButtonFormProps = { data: LicensedProduct; closeModal: () => void };

export const BigBlueButtonForm = ({ data, closeModal }: BigBlueButtonFormProps) => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const productVersion = data.organizationConnection?.edges[0].properties.version?.toLowerCase();

  const context = useMemoizedCacheTag('PRODUCTS');
  const [BBBsQueryResult] = useBigBlueButtonsQuery({ variables: { where: { uuid: data.uuid ?? '0' } }, context });
  const [, updateBBBs] = useUpdateBigBlueButtonsMutation();
  const [, createBBBs] = useCreateBigBlueButtonsMutation();
  const handleClose = () => {
    closeModal();
  };

  const proOpts = [
    {
      label: t('bigBlueButton.pro.option_1'),
      value: 50,
    },
    {
      label: t('bigBlueButton.pro.option_2'),
      value: 150,
    },
    {
      label: t('bigBlueButton.pro.option_3'),
      value: 300,
    },

    {
      label: t('bigBlueButton.pro.option_4'),
      value: 550,
    },
    {
      label: t('bigBlueButton.pro.option_5'),
      value: 800,
    },
    {
      label: t('bigBlueButton.pro.option_6'),
      value: 1050,
    },
    {
      label: t('bigBlueButton.pro.option_7'),
      value: 0,
    },
  ];

  const notProOpts = [
    {
      label: t('bigBlueButton.notPro.option_1'),
      value: 100,
    },
    {
      label: t('bigBlueButton.notPro.option_2'),
      value: 250,
    },
    {
      label: t('bigBlueButton.notPro.option_3'),
      value: 500,
    },

    {
      label: t('bigBlueButton.notPro.option_4'),
      value: 750,
    },
    {
      label: t('bigBlueButton.notPro.option_5'),
      value: 1000,
    },
    {
      label: t('bigBlueButton.notPro.option_6'),
      value: 0,
    },
  ];
  const selectOpts: SelectOptionType[] = productVersion === 'pro' ? proOpts : notProOpts;

  const currentBooking = BBBsQueryResult.data?.bigBlueButtons[0];
  const currentBookingProps = currentBooking?.organizationConnection.edges[0]?.properties;

  const initialValues: BBBValuesType = {
    apiUrl: currentBooking?.apiUrl ?? '',
    apiSecret: '',
    allowRecording: currentBooking?.allowRecording ?? false,
    version: bookedBBBSlots(currentBookingProps?.version),
    notBefore: ensureDate(currentBookingProps?.notBefore).toUTCString(),
    notAfter: ensureDate(currentBookingProps?.notAfter).toUTCString(),
    maxParticipantsPerMeeting: currentBooking?.maxParticipantsPerMeeting ?? null,
  };

  const onSubmit = async (values: BBBValuesType) => {
    if (perms?.canUpdateBBB({ uuid: organizationUuid })) {
      if (data.uuid === undefined) {
        const resp = await createBBBs(
          {
            input: [
              {
                apiUrl: values.apiUrl,
                // apiSecret: values.apiSecret,
                allowRecording: values.allowRecording,
                maxParticipantsPerMeeting: values.maxParticipantsPerMeeting,
                organization: {
                  connect: {
                    overwrite: true,
                    edge: {
                      notBefore: values.notBefore,
                      notAfter: values.notAfter,
                      version: values.version?.toString(),
                    },
                    where: { node: { uuid: organizationUuid } },
                  },
                },
              },
            ],
          },
          context,
        );
        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      } else {
        const resp = await updateBBBs(
          {
            update: {
              apiUrl: values.apiUrl,
              // apiSecret: values.apiSecret,
              allowRecording: values.allowRecording,
              maxParticipantsPerMeeting: values.maxParticipantsPerMeeting,
              organization: {
                update: {
                  edge: {
                    notBefore: values.notBefore,
                    notAfter: values.notAfter,
                    version: values.version?.toString(),
                  },
                },
              },
            },
            where: { uuid: data.uuid },
          },
          context,
        );
        if (resp.error) {
          showErrorToast(resp.error);
        } else {
          showSuccessToast(t('common.saved'));
          handleClose();
        }
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ resetForm, setFieldValue, handleChange, setFieldTouched, handleBlur, values, isSubmitting, errors }) => {
        const onClose = () => {
          resetForm();
          handleClose();
        };

        return (
          <>
            <Form>
              <DatePicker
                label={t('institution.bookedFromDate')}
                onChange={(e) => setFieldValue('notBefore', e?.toUTCString())}
                value={ensureDate(values.notBefore)}
                name={'notBefore'}
                showMonthYearDropdown
              />

              <DatePicker
                label={t('institution.bookedUntilDate')}
                onChange={(e) => setFieldValue('notAfter', e?.toUTCString())}
                value={ensureDate(values.notAfter)}
                name={'notAfter'}
                showMonthYearDropdown
              />
              <Checkbox
                className='mb-2'
                label={t('settings.bbbAllowRecordings')}
                name={'allowRecording'}
                onChange={handleChange}
                checked={values.allowRecording ?? false}
                error={errors.allowRecording}
              />
              <Select
                options={selectOpts}
                onChange={async (event) => {
                  const a: SelectOptionType | null = event as SingleValue<SelectOptionType>;
                  await setFieldTouched('connections', true);
                  await setFieldValue('connections', a?.value);
                }}
                value={selectOpts.find((s) => s.value === values.version)}
                name={'connections'}
                label={t('settings.bbbNumberConnections')}
              />
              <div className='mb-2'>{t('settings.bbbCustomInfo')}</div>
              <Input
                className='mb-2'
                label={t('settings.maxParticipantsPerMeeting')}
                name={'maxParticipantsPerMeeting'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.maxParticipantsPerMeeting?.toString()}
                error={errors.maxParticipantsPerMeeting}
              />
              <Input
                className='mb-2'
                label={t('settings.bbbApiUrl')}
                name={'apiUrl'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apiUrl?.toString()}
                error={errors.apiUrl}
              />
              <Input
                className='mb-2'
                label={t('settings.bbbApiSecret')}
                name={'apiSecret'}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.apiSecret?.toString()}
                error={errors.apiSecret}
              />

              <ModalBottomButtons errors={errors} closeButton={{ callback: onClose }} isLoading={isSubmitting} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
