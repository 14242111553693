import { useTranslation } from 'react-i18next';
import styles from './DivisionsForm.module.scss';
import { Field, FieldArray, Form, Formik, FormikHelpers, getIn } from 'formik';
import {
  AddIcon,
  Button,
  DeleteIcon,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Tooltip,
} from '@bp/ui-components';
import { BpClassType, BpDivisionType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import {
  useClassGroupsQuery,
  useCreateClassGroupMutation,
  useCreateDivisionMutation,
  useDivisionsQuery,
  useUpdateClassGroupMutation,
  useUpdateDivisionMutation,
  useDeleteClassGroupMutation,
  UpdateClassGroupMutationVariables,
  CreateClassGroupMutationVariables,
  DeleteClassGroupMutationVariables,
  CreateDivisionMutationVariables,
  UpdateDivisionMutationVariables,
  DeleteDivisionMutationVariables,
  useDeleteDivisionMutation,
} from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { v4 } from 'uuid';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { useConfirm } from 'hooks/useConfirm';

type DivisionsFormProps = {
  bpClass: BpClassType;
  onLoading: (isLoading: boolean) => void;
  onClose: () => void;
};

type GroupFormType = {
  uuid: string;
  name: string;
  shortName: string;
  isNew: boolean;
};

type DivisionFormType = {
  uuid: string;
  name: string;
  isNew: boolean;
  groups: GroupFormType[];
};

type DivisionsFormType = {
  divisions: DivisionFormType[];
};

export const DivisionsForm = ({ bpClass, onLoading, onClose }: DivisionsFormProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const divisionContext = useMemoizedCacheTag('DIVISION');
  const classGroupContext = useMemoizedCacheTag('CLASSGROUP');
  const updateContext = useMemoizedCacheTag(['DIVISION', 'CLASSGROUP']);

  const [, createDivision] = useCreateDivisionMutation();
  const [, updateDivision] = useUpdateDivisionMutation();
  const [, deleteDivision] = useDeleteDivisionMutation();
  const [, createClassGroup] = useCreateClassGroupMutation();
  const [, updateClassGroup] = useUpdateClassGroupMutation();
  const [, deleteClassGroup] = useDeleteClassGroupMutation();

  const { ConfirmationDialog: GroupConfirmDialog, confirm: confirmGroupDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('classGroups.title.singular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const { ConfirmationDialog: DivisionConfirmDialog, confirm: confirmDivisionDelete } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('divisions.title.singular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const [{ data: divisionsData }] = useDivisionsQuery({
    variables: { where: { class: { uuid: bpClass.uuid }, organization: { uuid: organizationUuid } } },
    context: divisionContext,
  });

  const [{ data: groupsData }] = useClassGroupsQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: classGroupContext,
  });

  const divisions: BpDivisionType[] =
    divisionsData?.divisions.map((d) => {
      return {
        uuid: d.uuid,
        name: d.name,
        classUuid: bpClass.uuid,
        classGroupUuids: d.groups.map((g) => g.uuid),
        source: d.managedBy,
      };
    }) ?? [];

  const initialValues: DivisionsFormType = {
    divisions: divisions.map((division) => {
      const divisionGroups = groupsData?.classGroups.filter((g) => g.division.uuid === division.uuid) ?? [];
      return {
        ...division,
        isNew: false,
        groups: divisionGroups.map((g) => {
          return {
            uuid: g.uuid,
            name: g.name,
            shortName: g.shortName,
            isNew: false,
          };
        }),
      };
    }),
  };

  function getDivisionCreate(division: DivisionFormType): CreateDivisionMutationVariables {
    return {
      input: {
        name: division.name,
        managedBy: 'bp',
        foreignRefId: [],
        class: {
          connect: {
            where: {
              node: {
                uuid: bpClass.uuid,
              },
            },
          },
        },
        organization: {
          connect: {
            where: {
              node: {
                uuid: organizationUuid,
              },
            },
          },
        },
      },
    };
  }

  function getDivisionEdit(division: DivisionFormType): UpdateDivisionMutationVariables {
    return {
      update: {
        name: division.name,
      },
      where: {
        uuid: division.uuid,
      },
    };
  }

  function getDivisionDelete(division: DivisionFormType): DeleteDivisionMutationVariables {
    return {
      delete: {
        groups: division.groups.map((g) => {
          return {
            where: {
              node: {
                uuid: g.uuid,
              },
            },
          };
        }),
      },
      where: {
        uuid: division.uuid,
      },
    };
  }

  function getGroupCreate(
    group: GroupFormType,
    index: number,
    divisionUuid: string,
  ): CreateClassGroupMutationVariables {
    return {
      input: {
        name: group.name,
        shortName: group.shortName,
        managedBy: 'bp',
        foreignRefId: [],
        division: {
          connect: {
            edge: {
              order: index,
            },
            where: {
              node: {
                uuid: divisionUuid,
              },
            },
          },
        },
        organization: {
          connect: {
            where: {
              node: {
                uuid: organizationUuid,
              },
            },
          },
        },
      },
    };
  }

  function getGroupEdit(group: GroupFormType): UpdateClassGroupMutationVariables {
    return {
      update: {
        name: group.name,
        shortName: group.shortName,
      },
      where: {
        uuid: group.uuid,
      },
    };
  }

  function getGroupDelete(group: GroupFormType): DeleteClassGroupMutationVariables {
    return {
      delete: {
        members: [], //TODO: implement
      },
      where: {
        uuid: group.uuid,
      },
    };
  }

  async function createGroups(groupsToCreate: CreateClassGroupMutationVariables[]) {
    let createFinished = 0;
    for (const input of groupsToCreate) {
      const result = await createClassGroup(input, classGroupContext);
      if (result.error) {
        showErrorToast(result.error);
        return;
      }
      createFinished++;
    }
    if (createFinished > 0) {
      showSuccessToast(t('classGroups.groupCreated', { count: createFinished, groups: groupsToCreate.length }));
    }
  }

  async function editGroups(groupsToEdit: UpdateClassGroupMutationVariables[]) {
    let updateFinished = 0;
    for (const update of groupsToEdit) {
      const result = await updateClassGroup(update, updateContext);
      if (result.error) {
        showErrorToast(result.error);
        return;
      }
      updateFinished++;
    }
    if (updateFinished > 0) {
      showSuccessToast(t('classGroups.groupEdited', { count: updateFinished, groups: groupsToEdit.length }));
    }
  }

  async function deleteGroups(groupsToDelete: DeleteClassGroupMutationVariables[]) {
    let deleteFinished = 0;
    for (const remove of groupsToDelete) {
      const result = await deleteClassGroup(remove, updateContext);
      if (result.error) {
        showErrorToast(result.error);
        return;
      }
      deleteFinished++;
    }
    if (deleteFinished > 0) {
      showSuccessToast(t('classGroups.groupDeleted', { count: deleteFinished, groups: groupsToDelete.length }));
    }
  }

  const handleSubmit = async (values: DivisionsFormType, formHelpers: FormikHelpers<DivisionsFormType>) => {
    let hasError = false;

    if (values.divisions) {
      values.divisions.forEach((division, divisionIndex) => {
        if (!division.name) {
          formHelpers.setFieldError(`divisions.${divisionIndex}.name`, t('validation.required.type'));
          hasError = true;
        }
        if (!division.groups || division.groups.length === 0) {
          formHelpers.setFieldError(`divisions.${divisionIndex}.name`, t('validation.atLeastOneGroup'));
          hasError = true;
        } else {
          division.groups.forEach((group, groupIndex) => {
            if (!group.name) {
              formHelpers.setFieldError(
                `divisions.${divisionIndex}.groups.${groupIndex}.name`,
                t('validation.required.designation'),
              );
              hasError = true;
            }
            if (!group.shortName) {
              formHelpers.setFieldError(
                `divisions.${divisionIndex}.groups.${groupIndex}.shortName`,
                t('validation.required.shortName'),
              );
              hasError = true;
            }
          });
        }
      });
    } else {
      hasError = true;
    }
    if (hasError) {
      return;
    }

    onLoading(true);

    const initialDivisions = initialValues.divisions;
    const newDivisions = values.divisions;

    const divisionsToCreate: DivisionFormType[] = newDivisions.filter((division) => division.isNew);

    const divisionsToEdit: DivisionFormType[] = newDivisions.filter(
      (division) =>
        !division.isNew &&
        initialDivisions.some(
          (initialDivision) =>
            (division.uuid === initialDivision.uuid && division.name !== initialDivision.name) ||
            division.groups.length !== initialDivision.groups.length ||
            division.groups.some(
              (group) =>
                !group.isNew &&
                initialDivision.groups.some(
                  (initialGroup) =>
                    group.uuid === initialGroup.uuid &&
                    (group.name !== initialGroup.name || group.shortName !== initialGroup.shortName),
                ),
            ),
        ),
    );

    const divisionsToDelete: DivisionFormType[] = initialDivisions.filter(
      (division) => !newDivisions.some((newDivision) => division.uuid === newDivision.uuid),
    );

    for (const division of divisionsToCreate) {
      const divisionCreateResult = await createDivision(getDivisionCreate(division), divisionContext);

      const newDivisionUuid = divisionCreateResult.data?.createDivisions.divisions[0].uuid;
      if (divisionCreateResult.error) {
        showErrorToast(divisionCreateResult.error);
        return;
      } else if (!newDivisionUuid) {
        return;
      } else {
        showSuccessToast(t('divisions.divisionCreated', { name: division.name }));
      }

      const groupsToCreate: CreateClassGroupMutationVariables[] = division.groups
        .filter((group) => group.isNew)
        .map((group, groupIndex) => {
          return getGroupCreate(group, groupIndex, newDivisionUuid);
        });

      await createGroups(groupsToCreate);
    }

    for (const division of divisionsToEdit) {
      const divisionUpdateResult = await updateDivision(getDivisionEdit(division), updateContext);

      if (divisionUpdateResult.error) {
        showErrorToast(divisionUpdateResult.error);
      } else {
        showSuccessToast(t('divisions.divisionEdited', { name: division.name }));
      }

      const initialGroups = initialValues.divisions.find((d) => d.uuid === division.uuid)?.groups ?? [];
      const newGroups = division.groups;

      const groupsToCreate: CreateClassGroupMutationVariables[] = division.groups
        .filter((group) => group.isNew)
        .map((group, groupIndex) => getGroupCreate(group, groupIndex, division.uuid));

      const groupsToEdit: UpdateClassGroupMutationVariables[] = newGroups
        .filter(
          (group) =>
            !group.isNew &&
            initialGroups.some(
              (initialGroup) =>
                group.uuid === initialGroup.uuid &&
                (group.name !== initialGroup.name || group.shortName !== initialGroup.shortName),
            ),
        )
        .map((group) => getGroupEdit(group));

      const groupsToDelete: DeleteClassGroupMutationVariables[] = initialGroups
        .filter((group) => !newGroups.some((newGroup) => group.uuid === newGroup.uuid))
        .map((group) => getGroupDelete(group));

      await createGroups(groupsToCreate);
      await editGroups(groupsToEdit);
      await deleteGroups(groupsToDelete);
    }

    for (const division of divisionsToDelete) {
      const groupsToDelete: DeleteClassGroupMutationVariables[] = division.groups.map((group) => getGroupDelete(group));

      await deleteGroups(groupsToDelete);

      const divisionDeleteResult = await deleteDivision(getDivisionDelete(division), updateContext);

      if (divisionDeleteResult.error) {
        showErrorToast(divisionDeleteResult.error);
      } else {
        showSuccessToast(t('divisions.divisionDeleted', { name: division.name }));
      }
    }

    onLoading(false);
    formHelpers.resetForm();
    onClose();
  };

  return (
    <Formik<DivisionsFormType>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnBlur={true}
      enableReinitialize={true}
    >
      {({ errors, values, isSubmitting, touched, dirty, isValidating, handleSubmit, handleChange }) => {
        return (
          <Form className={styles['divisions-form']}>
            <Grid useFormGap>
              <FieldArray name='divisions'>
                {(arrayHelper) => {
                  return (
                    <>
                      {values.divisions.map((division, divisionIndex) => {
                        return (
                          <div key={division.uuid} className={styles['division-wrapper']}>
                            <GridRow
                              headline={`${t('divisions.title.singular')} ${divisionIndex + 1}`}
                              spacingBottom='s'
                            >
                              <GridColumn width={11}>
                                <GridRow>
                                  <GridColumn width={6}>
                                    <Field
                                      name={`${arrayHelper.name}.${divisionIndex}.name`}
                                      label={t('common.type')}
                                      value={division.name}
                                      onChange={handleChange}
                                      as={Input}
                                      error={
                                        getIn(touched, `${arrayHelper.name}.${divisionIndex}.name`) &&
                                        getIn(errors, `${arrayHelper.name}.${divisionIndex}.name`)
                                          ? getIn(errors, `${arrayHelper.name}.${divisionIndex}.name`)
                                          : undefined
                                      }
                                    />
                                  </GridColumn>
                                  <GridColumn width={6}>
                                    <Input
                                      name='bpClassName'
                                      label={t('divisions.assignedClass')}
                                      readonly
                                      value={bpClass.name}
                                    />
                                  </GridColumn>
                                </GridRow>
                              </GridColumn>
                              <GridColumn width={1} align='end'>
                                <Button
                                  className='mt-4'
                                  hierarchy='ghost'
                                  icon={
                                    division.groups.length > 0 ? (
                                      <Tooltip content={t('divisions.deleteGroupsFirst')}>
                                        <DeleteIcon />
                                      </Tooltip>
                                    ) : (
                                      <DeleteIcon />
                                    )
                                  }
                                  disabled={division.groups.length > 0}
                                  onClick={async () => {
                                    if (division.isNew) {
                                      arrayHelper.remove(divisionIndex);
                                    } else {
                                      const res = await confirmDivisionDelete();
                                      if (res) arrayHelper.remove(divisionIndex);
                                    }
                                  }}
                                />
                              </GridColumn>
                            </GridRow>
                            <div className={styles['group-wrapper']}>
                              {division.groups.map((group, groupIndex) => {
                                return (
                                  <GridRow key={group.uuid} spacingBottom='none' spacingTop='none'>
                                    <GridColumn width={11}>
                                      <GridRow>
                                        <GridColumn width={10}>
                                          <Field
                                            name={`${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.name`}
                                            label={`${t('classGroups.title.singular')} ${groupIndex + 1} ${t('common.designation')}`}
                                            value={group.name}
                                            onChange={handleChange}
                                            as={Input}
                                            error={
                                              getIn(
                                                touched,
                                                `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.name`,
                                              ) &&
                                              getIn(
                                                errors,
                                                `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.name`,
                                              )
                                                ? getIn(
                                                    errors,
                                                    `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.name`,
                                                  )
                                                : undefined
                                            }
                                          />
                                        </GridColumn>
                                        <GridColumn width={2}>
                                          <Field
                                            name={`${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.shortName`}
                                            label={`${t('classGroups.title.singular')} ${groupIndex + 1} ${t('common.shortName')}`}
                                            value={group.shortName}
                                            onChange={handleChange}
                                            as={Input}
                                            error={
                                              getIn(
                                                touched,
                                                `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.shortName`,
                                              ) &&
                                              getIn(
                                                errors,
                                                `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.shortName`,
                                              )
                                                ? getIn(
                                                    errors,
                                                    `${arrayHelper.name}.${divisionIndex}.groups.${groupIndex}.shortName`,
                                                  )
                                                : undefined
                                            }
                                          />
                                        </GridColumn>
                                      </GridRow>
                                    </GridColumn>
                                    <GridColumn width={1} align='end'>
                                      <Button
                                        className='mt-4'
                                        hierarchy='ghost'
                                        icon={<DeleteIcon />}
                                        onClick={async () => {
                                          if (group.isNew) {
                                            const groups = values.divisions[divisionIndex].groups.filter(
                                              (g) => g.uuid !== group.uuid,
                                            );
                                            arrayHelper.replace(divisionIndex, {
                                              ...values.divisions[divisionIndex],
                                              groups,
                                            });
                                          } else {
                                            const res = await confirmGroupDelete();
                                            if (res) {
                                              const groups = values.divisions[divisionIndex].groups.filter(
                                                (g) => g.uuid !== group.uuid,
                                              );
                                              arrayHelper.replace(divisionIndex, {
                                                ...values.divisions[divisionIndex],
                                                groups,
                                              });
                                            }
                                          }
                                        }}
                                      />
                                    </GridColumn>
                                  </GridRow>
                                );
                              })}
                            </div>

                            <GridRow spacingTop='s'>
                              <Button
                                hierarchy='tertiary'
                                icon={<AddIcon />}
                                onClick={() => {
                                  const groups = values.divisions[divisionIndex].groups;
                                  groups.push({
                                    uuid: v4(),
                                    name: '',
                                    shortName: '',
                                    isNew: true,
                                  });
                                  arrayHelper.replace(divisionIndex, {
                                    ...values.divisions[divisionIndex],
                                    groups,
                                  });
                                }}
                              >
                                {t('classGroups.addGroup')}
                              </Button>
                            </GridRow>
                          </div>
                        );
                      })}

                      <GridRow>
                        <Button
                          hierarchy='tertiary'
                          icon={<AddIcon />}
                          onClick={() => {
                            arrayHelper.push({
                              uuid: v4(),
                              name: '',
                              groups: [],
                              isNew: true,
                            });
                          }}
                        >
                          {t('divisions.addDivision')}
                        </Button>
                      </GridRow>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: isSubmitting || !dirty || isValidating,
                callback: () => {
                  handleSubmit();
                },
              }}
              errors={errors}
            />

            <DivisionConfirmDialog />
            <GroupConfirmDialog />
          </Form>
        );
      }}
    </Formik>
  );
};
