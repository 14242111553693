import { useEffect, useRef } from 'react';
import { useNetworkState } from '@uidotdev/usehooks';
import { BpMatrixClient } from '../../utils/matrixClient';
import type { MatrixClient } from 'matrix-js-sdk';

const SLOW_POLLING_INTERVAL = 5000;
const FAST_POLLING_INTERVAL = 2000;

type MatrixClientPromise = Promise<MatrixClient | null>;

export const useMatrixLifecycle = (): MatrixClientPromise => {
  const matrix = BpMatrixClient.instance();
  const network = useNetworkState();
  const pollingIntervalRef = useRef<number | null>(null);
  const isCleanedUpRef = useRef(false);

  useEffect(() => {
    isCleanedUpRef.current = false;

    const checkAndUpdateMatrixClient = async (): Promise<number> => {
      try {
        const client = await matrix;
        if (!client || isCleanedUpRef.current) {
          return SLOW_POLLING_INTERVAL;
        }
        
        if (!network.online) {
          if (client.clientRunning) {
            try {
              await client.stopClient();
            } catch (error) {
              console.error('Error stopping Matrix client:', error);
            }
          }
          return FAST_POLLING_INTERVAL;
        }

        // Check if client should be running but isn't
        if (!client.clientRunning && network.online) {
          try {
            await client.startClient();
          } catch (error) {
            console.error('Error starting Matrix client:', error);
            return FAST_POLLING_INTERVAL;
          }
        }

        return SLOW_POLLING_INTERVAL;
      } catch (error) {
        console.error('Error in Matrix client lifecycle:', error);
        return FAST_POLLING_INTERVAL;
      }
    };

    const startPolling = async (): Promise<void> => {
      if (isCleanedUpRef.current) return;

      try {
        const interval = await checkAndUpdateMatrixClient();
        if (!isCleanedUpRef.current) {
          pollingIntervalRef.current = window.setTimeout(startPolling, interval);
        }
      } catch (error) {
        console.error('Error in Matrix polling:', error);
        if (!isCleanedUpRef.current) {
          pollingIntervalRef.current = window.setTimeout(startPolling, FAST_POLLING_INTERVAL);
        }
      }
    };

    void startPolling();

    return () => {
      isCleanedUpRef.current = true;
      if (pollingIntervalRef.current !== null) {
        window.clearTimeout(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, [matrix, network.online]);

  return matrix;
}; 