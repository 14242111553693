import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { DivisionsForm } from '../forms/DivisionsForm';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useState } from 'react';

export type DivisionsModalProps = {
  bpClass: BpClassType;
  isOpen: boolean;
  onClose: () => void;
};

export const DivisionsModal = ({ bpClass, isOpen, onClose }: DivisionsModalProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal loading={loading} title={t('divisions.edit')} isOpen={isOpen} onRequestClose={onClose}>
      <DivisionsForm bpClass={bpClass} onLoading={setLoading} onClose={onClose} />
    </Modal>
  );
};
