import { SelectOptionType } from '@bp/ui-components';

function getGrades() {
  return Array(14)
    .fill(1)
    .map((element, index) => index.toString());
}

function getGradeOptions(): SelectOptionType[] {
  return getGrades().map((g) => {
    return { value: g, label: g };
  });
}

export { getGrades, getGradeOptions };
