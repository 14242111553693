import { urqlClient } from '../urqlClient';
import { GroupAsCourseDocument, GroupAsGroupsDocument } from '../../client/bp-graphql-client-defs';

export const courseLoader = (courseUuid?: string) => {
  return urqlClient
    .query(
      GroupAsCourseDocument,
      {
        where: { uuid: courseUuid },
      },
      { additionalTypenames: ['Course'] },
    )
    .toPromise();
};

export const groupLoader = (groupUuid?: string) => {
  return urqlClient
    .query(
      GroupAsGroupsDocument,
      {
        where: { uuid: groupUuid },
      },
      { additionalTypenames: ['Group'] },
    )
    .toPromise();
};
