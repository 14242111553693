import { Modal } from '@bp/ui-components';
import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import { ClassForm } from '../forms/ClassForm';
import { UsedColors } from 'utils/colorHelper';

export type ClassesListModalProps = {
  selectedClass?: BpClassType;
  colorsInUse: (UsedColors | null)[];
  isOpen: boolean;
  onClose: () => void;
};

export const ClassesListModal = ({ selectedClass, colorsInUse, isOpen, onClose }: ClassesListModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={selectedClass ? t('classes.edit') : t('classes.add')} isOpen={isOpen} onRequestClose={onClose}>
      <ClassForm selectedClass={selectedClass} colorsInUse={colorsInUse} onClose={onClose} />
    </Modal>
  );
};
