export const Products = {
  BILDUNGSPLATTFORM: 'Bildungsplattform',
  NEXTCLOUD: 'Nextcloud',
  BIGBLUEBUTTON: 'BigBlueButton',
  ZOOM: 'Zoom',
  THREEMA: 'Threema',
  THREEMABROADCAST: 'ThreemaBroadcast',
  // MAILBOX: 'Mailbox.org',
};

export type OrganizationConnectionEdges = {
  edges: [{ properties: { version: string; notBefore: string; notAfter: string } }];
};

export type LicensedProductThreema = {
  __typename?: 'Threema';
  uuid?: string;
  apiToken?: string;
  organizationConnection?: OrganizationConnectionEdges;
};

export type LicensedProductNextcloud = {
  __typename?: 'Nextcloud';
  uuid?: string;
  serverUri?: string;
  serverAliase?: string;
  adminUser?: string;
  adminPass?: string;
  organizationConnection?: OrganizationConnectionEdges;
};

export type LicensedProduct =
  | {
      __typename?: 'BigBlueButton';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | LicensedProductNextcloud
  | LicensedProductThreema
  | {
      __typename?: 'ThreemaBroadcast';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | {
      __typename?: 'Zoom';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    }
  | {
      __typename?: 'Bildungsplattform';
      uuid?: string;
      organizationConnection?: OrganizationConnectionEdges;
    };
