import React, { Suspense, useEffect } from 'react';
import { Provider } from 'urql';
import { AppContextProvider } from './context/AppContext';
import { BpAuthProvider } from './BpAuthProvider';
import { ErrorBoundary } from './ErrorBoundary';
import { urqlClient } from './utils/urqlClient';
import { IsDesktopContextProvider } from './context/IsDesktop';
import { RequireMatrix } from './RequireMatrix';
import styles from './App.module.scss';
import { Header } from './components/Header/Header';
import { LazyLoader, Toast } from '@bp/ui-components';
import { Outlet } from 'react-router-dom';
import { SidebarWrapper } from './components/SidebarWrapper/SidebarWrapper';
import classNames from 'classnames';
import { MobileMenu } from './components/MobileMenu/MobileMenu';
import { OrganizationConfigContextProvider } from './context/OrganizationConfigContext';
import { RequireAuth } from './RequireAuth';
import { Browsers, getBrowser } from 'utils/browsers';
import { RequireBackend } from 'RequireBackend';
import { PushNotificationWrapper } from 'components/PushNotificationWrapper';

export function App() {
  const browser = getBrowser();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    (function () {
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = 'https://matomo.tks.eu/js/container_fiWk8aXC_dev_0adbd5f799f9ebfc57fb7395.js';
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      s.parentNode.insertBefore(g, s);
    })();
  }, []);

  const appClasses = classNames(styles['app-content'], {
    [styles.chrome]: browser === Browsers.Chrome,
    [styles.safari]: browser === Browsers.Safari,
  });

  return (
    <ErrorBoundary>
      <BpAuthProvider>
        <RequireAuth>
          <RequireMatrix>
            <RequireBackend>
              <AppContextProvider>
                <Provider value={urqlClient}>
                  <OrganizationConfigContextProvider>
                    <IsDesktopContextProvider>
                      <SidebarWrapper>
                        <PushNotificationWrapper>
                          <div className={appClasses} data-cy='layoutWrapper'>
                            <Header className={styles.header} />
                            <main
                              className={classNames(styles['app-container'], 'has-scrollbar')}
                              data-cy='layoutContentColumn'
                            >
                              <Suspense fallback={<LazyLoader embedded transparent />}>
                                <Outlet />
                              </Suspense>
                            </main>
                            <MobileMenu className={styles['mobile-menu']} />
                          </div>
                          <Toast />
                        </PushNotificationWrapper>
                      </SidebarWrapper>
                    </IsDesktopContextProvider>
                  </OrganizationConfigContextProvider>
                </Provider>
              </AppContextProvider>
            </RequireBackend>
          </RequireMatrix>
        </RequireAuth>
      </BpAuthProvider>
    </ErrorBoundary>
  );
}
