import {
  Checkbox,
  DivisionIllustration,
  EmptyState,
  Grid,
  GridRow,
  ModalBottomButtons,
  Tooltip,
} from '@bp/ui-components';
import {
  useDivisionsQuery,
  useClassGroupsQuery,
  useBpMinimalProfileQuery,
  useUpdateClassGroupMutation,
  UpdateClassGroupMutationVariables,
  ClassGroupMembersUpdateFieldInput,
} from 'client/bp-graphql-client-defs';
import { Form, Formik } from 'formik';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { BpClassType, BpDivisionType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import styles from './ClassGroupProfilesForm.module.scss';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';

type ClassGroupProfilesFormProps = {
  bpClass: BpClassType;
  onLoad: (isLoading: boolean) => void;
  onClose: () => void;
};

type ProfileFormType = {
  uuid: string;
  name: string;
};

type GroupFormType = {
  uuid: string;
  name: string;
  shortName: string;
  profiles: ProfileFormType[];
};

type DivisionFormType = {
  uuid: string;
  name: string;
  profiles: ProfileFormType[];
  groups: GroupFormType[];
};

type ProfilesFormType = {
  divisions: DivisionFormType[];
};

export const ClassGroupProfilesForm = ({ bpClass, onLoad, onClose }: ClassGroupProfilesFormProps) => {
  const { t } = useTranslation();
  const organizationUuid = useAuthClaims().pimAuthClaims.getOrganizationUuid();

  const divisionContext = useMemoizedCacheTag('DIVISION');
  const classGroupContext = useMemoizedCacheTag('CLASSGROUP');
  const profileContext = useMemoizedCacheTag('PROFILE');

  const [, updateGroup] = useUpdateClassGroupMutation();

  const [{ data: profileData }] = useBpMinimalProfileQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: profileContext,
  });

  const [{ data: divisionsData }] = useDivisionsQuery({
    variables: { where: { class: { uuid: bpClass.uuid }, organization: { uuid: organizationUuid } } },
    context: divisionContext,
  });

  const [{ data: groupsData }] = useClassGroupsQuery({
    variables: {
      where: {
        organization: {
          uuid: organizationUuid,
        },
      },
    },
    context: classGroupContext,
  });

  const divisions: BpDivisionType[] =
    divisionsData?.divisions.map((d) => {
      return {
        uuid: d.uuid,
        name: d.name,
        classUuid: bpClass.uuid,
        classGroupUuids: d.groups.map((g) => g.uuid),
        source: d.managedBy,
      };
    }) ?? [];

  const initialValues: ProfilesFormType = {
    divisions: divisions.map((division) => {
      const divisionGroups = groupsData?.classGroups.filter((g) => g.division.uuid === division.uuid) ?? [];
      const classProfiles = profileData?.profiles.filter((p) => bpClass.profileUuids.some((c) => c === p.uuid)) ?? [];
      return {
        ...division,
        profiles: classProfiles.map((p) => {
          return {
            uuid: p.uuid,
            name: p.displayName ?? '',
          };
        }),
        groups: divisionGroups.map((g) => {
          const groupProfiles =
            profileData?.profiles.filter((p) => g.membersConnection.edges.some((edge) => edge.node.uuid === p.uuid)) ??
            [];
          return {
            uuid: g.uuid,
            name: g.name,
            shortName: g.shortName,
            profiles: groupProfiles.map((p) => {
              return {
                uuid: p.uuid,
                name: p.displayName ?? '',
              };
            }),
          };
        }),
      };
    }),
  };

  const handleSubmit = async (values: ProfilesFormType) => {
    onLoad(true);

    const groupsToEdit: UpdateClassGroupMutationVariables[] = [];

    values.divisions.forEach((division) => {
      const initialDivisions = initialValues.divisions.find((d) => d.uuid === division.uuid);

      division.groups.forEach((group) => {
        const initialGroup = initialDivisions?.groups.find((g) => g.uuid === group.uuid);
        const profilesToConnect: ClassGroupMembersUpdateFieldInput[] = [];
        const profilesToDisconnect: ClassGroupMembersUpdateFieldInput[] = [];

        division.profiles.forEach((profile) => {
          group?.profiles
            .filter((p) => p.uuid === profile.uuid && !initialGroup?.profiles.some((p) => p.uuid === profile.uuid))
            .forEach((profile) => {
              profilesToConnect.push({
                connect: [
                  {
                    where: {
                      node: {
                        uuid: profile.uuid,
                      },
                    },
                  },
                ],
              });
            });

          initialGroup?.profiles
            .filter((p) => p.uuid === profile.uuid && !group?.profiles.some((p) => p.uuid === profile.uuid))
            .forEach((profile) => {
              profilesToDisconnect.push({
                disconnect: [
                  {
                    where: {
                      node: {
                        uuid: profile.uuid,
                      },
                    },
                  },
                ],
              });
            });
        });

        if (profilesToConnect.length > 0 || profilesToDisconnect.length > 0) {
          groupsToEdit.push({
            update: {
              members: [...profilesToConnect, ...profilesToDisconnect],
            },
            where: {
              uuid: group.uuid,
            },
          });
        }
      });
    });

    let updateFinished = 0;
    for (const update of groupsToEdit) {
      const result = await updateGroup(update, classGroupContext);
      if (result.error) {
        showErrorToast(result.error);
        return;
      }
      updateFinished++;
    }
    if (updateFinished > 0) {
      showSuccessToast(t('classGroups.groupEdited', { count: updateFinished, groups: groupsToEdit.length }));
    }

    onLoad(false);
    onClose();
  };

  return (
    <Formik<ProfilesFormType> onSubmit={handleSubmit} initialValues={initialValues}>
      {({ errors, values, isSubmitting, dirty, isValidating, handleSubmit, setFieldValue }) => {
        return (
          <Form className={styles['class-group-profiles-form']}>
            <Grid useFormGap>
              {values.divisions.length === 0 ? (
                <EmptyState forcedHeight='30vh' icon={<DivisionIllustration />} title={t('divisions.noDivisions')} />
              ) : (
                values.divisions.map((division, divisionIndex) => {
                  const gridWidth = `repeat(${division.groups.length}, 70px)`;

                  return (
                    <GridRow headline={division.name} key={division.uuid}>
                      <div className={styles.division}>
                        <div className={styles.header} style={{ gridTemplateColumns: `200px ${gridWidth}` }}>
                          <div>{t('students.titleSingular')}</div>
                          {division.groups.map((group) => {
                            return (
                              <Tooltip key={group.uuid} content={group.name} triggerClass={styles['group-name']}>
                                {group.shortName}
                              </Tooltip>
                            );
                          })}
                        </div>

                        {division.profiles.length === 0 ? (
                          <EmptyState
                            size='small'
                            hideIcon
                            forcedHeight='10vh'
                            title={t('classes.noProfiles')}
                            subtitle={t('classes.noProfilesHint')}
                          />
                        ) : (
                          division.profiles.map((profile) => {
                            return (
                              <div
                                key={profile.uuid}
                                className={styles.profile}
                                style={{ gridTemplateColumns: `200px ${gridWidth}` }}
                              >
                                <div className={styles['student-name']}>{profile.name}</div>
                                {division.groups.map((group, groupIndex) => {
                                  return (
                                    <div key={profile.uuid + '-' + group.uuid}>
                                      <Checkbox
                                        className={styles.checkbox}
                                        name={`${profile.name} in ${group.name}`}
                                        onChange={(value) => {
                                          const currentGroup = values.divisions[divisionIndex].groups[groupIndex];
                                          if (value.target.checked) {
                                            currentGroup.profiles.push(profile);
                                          } else {
                                            currentGroup.profiles = currentGroup.profiles.filter(
                                              (p) => p.uuid !== profile.uuid,
                                            );
                                          }
                                          const currentDivision = values.divisions[divisionIndex];
                                          currentDivision.groups = currentDivision.groups.map((g) => {
                                            return g.uuid === currentGroup.uuid ? currentGroup : g;
                                          });
                                          const newDivisions = values.divisions.map((d) => {
                                            return d.uuid === currentDivision.uuid ? currentDivision : d;
                                          });

                                          setFieldValue('divisions', newDivisions);
                                        }}
                                        checked={group.profiles.some((g) => g.uuid === profile.uuid)}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </GridRow>
                  );
                })
              )}
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: isSubmitting || !dirty || isValidating,
                callback: () => {
                  handleSubmit();
                },
              }}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
