import { BpClassType } from 'pages/Institution/subpages/InstitutionClassesSubpage';
import { useTranslation } from 'react-i18next';
import { BpCard } from 'components/BpCard/BpCard';
import { ActionBar, Button, ClassesIllustration, EmptyState, ImportIcon } from '@bp/ui-components';
import { ClassesListModal } from './modals/ClassesListModal';
import { useMemo, useState } from 'react';
import { ClassesListItem } from './ClassesListItem';
import { useConfirm } from 'hooks/useConfirm';
import { DivisionsModal } from 'components/Divisions/modals/DivisionsModal';
import { DeleteClassDocument, useClassesQuery } from 'client/bp-graphql-client-defs';
import { useAuthClaims } from 'hooks/useAuthClaims';
import { useMutation } from 'urql';
import { getColorName } from 'utils/colorHelper';
import { useMemoizedCacheTag } from 'hooks/useMemoizedCacheTag';
import { showErrorToast } from 'utils/showErrorToast';
import { showSuccessToast } from 'utils/showSuccessToast';
import { ClassProfilesModal } from './modals/ClassProfilesModal';
import { ClassGroupProfilesModal } from 'components/ClassGroups/modals/ClassGroupProfilesModal';

export const ClassesList = () => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const { ConfirmationDialog, confirm } = useConfirm({
    defaultTitle: t('delete.headline'),
    defaultMessage: t('delete.message', { type: t('classes.titleSingular'), context: 'female' }),
    defaultConfirmText: t('delete.delete'),
  });

  const [selectedClass, setSelectedClass] = useState<BpClassType | null>(null);
  const [classModalOpen, setClassModalOpen] = useState<boolean>(false);
  const [classProfilesModalOpen, setClassProfilesModalOpen] = useState<boolean>(false);
  const [divisionsModalOpen, setDivisionsModalOpen] = useState<boolean>(false);
  const [groupProfilesModalOpen, setGroupProfilesModalOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const classesContext = useMemoizedCacheTag('CLASS');

  const [{ data: classesData }] = useClassesQuery({
    variables: { where: { organization: { uuid: organizationUuid } } },
    context: classesContext,
  });

  const [, deleteClass] = useMutation(DeleteClassDocument);

  const classes = useMemo((): BpClassType[] => {
    return (
      classesData?.classes.map((c) => {
        return {
          uuid: c.uuid,
          name: c.name,
          shortName: c.shortName,
          grade: c.grade ? Number(c.grade) : 0,
          gradeGroup: c.gradeGroup,
          tutor1Uuid: c?.tutorsConnection?.edges[0] ? c.tutorsConnection?.edges[0].node.uuid : '',
          tutor2Uuid: c?.tutorsConnection?.edges[1] ? c.tutorsConnection?.edges[1].node.uuid : '',
          tutor3Uuid: c?.tutorsConnection?.edges[2] ? c.tutorsConnection?.edges[2].node.uuid : '',
          color: {
            color: c.color ?? '',
            colorLabel: getColorName(c.color ?? ''),
          },
          source: c.managedBy,
          divisionUuids: c.divisionsConnection.edges.map((d) => d.node.uuid),
          profileUuids: c.membersConnection.edges.map((p) => p.node.uuid) ?? [],
        };
      }) ?? []
    );
  }, [classesData]);

  const filteredClasses = useMemo(() => {
    return classes?.filter((c) => c.name.includes(search)) ?? [];
  }, [classes, search]);

  const colorsInUse = classes.map((c) => c.color ?? null);

  function handleEditClass(uuid: string) {
    setSelectedClass(classes?.find((c) => c.uuid === uuid) ?? null);
    setClassModalOpen(true);
  }

  function handleAssignClassProfiles(uuid: string) {
    setSelectedClass(classes?.find((c) => c.uuid === uuid) ?? null);
    setClassProfilesModalOpen(true);
  }

  function handleEditDivisions(uuid: string) {
    setSelectedClass(classes?.find((c) => c.uuid === uuid) ?? null);
    setDivisionsModalOpen(true);
  }

  function handleAssignGroupProfiles(uuid: string) {
    setSelectedClass(classes?.find((c) => c.uuid === uuid) ?? null);
    setGroupProfilesModalOpen(true);
  }

  async function handleDelete(uuid: string) {
    const res = await confirm();
    if (res) {
      const response = await deleteClass({ where: { uuid: uuid } }, classesContext);
      if (response.error) {
        showErrorToast(response.error);
      } else {
        showSuccessToast(t('classes.classDeleted'));
      }
    }
  }

  return (
    <>
      <ActionBar
        onGlobalFilterChange={(value) => setSearch(value)}
        showAddButton
        onAddClick={() => setClassModalOpen(true)}
        extendedActionsRight={
          <Button disabled icon={<ImportIcon />} hierarchy='tertiary'>
            {t('classes.import')}
          </Button>
        }
      />
      {filteredClasses.length === 0 ? (
        <BpCard noPadding preventCollapsible>
          <EmptyState
            icon={<ClassesIllustration />}
            title={t('classes.empty.title')}
            subtitle={t('classes.empty.subtitle')}
            forcedHeight='30vh'
          />
        </BpCard>
      ) : (
        filteredClasses.map((c) => (
          <ClassesListItem
            key={c.uuid}
            bpClass={c}
            onEditClass={handleEditClass}
            onAssignClassProfiles={handleAssignClassProfiles}
            onEditDivisions={handleEditDivisions}
            onAssignGroupProfiles={handleAssignGroupProfiles}
            onDelete={handleDelete}
          />
        ))
      )}

      <ClassesListModal
        selectedClass={selectedClass ?? undefined}
        colorsInUse={colorsInUse}
        isOpen={classModalOpen}
        onClose={() => {
          setClassModalOpen(false);
          setSelectedClass(null);
        }}
      />

      {selectedClass && (
        <ClassProfilesModal
          bpClass={selectedClass}
          isOpen={classProfilesModalOpen}
          onClose={() => {
            setClassProfilesModalOpen(false);
            setSelectedClass(null);
          }}
        />
      )}

      {selectedClass && (
        <DivisionsModal
          bpClass={selectedClass}
          isOpen={divisionsModalOpen}
          onClose={() => {
            setDivisionsModalOpen(false);
            setSelectedClass(null);
          }}
        />
      )}

      {selectedClass && (
        <ClassGroupProfilesModal
          bpClass={selectedClass}
          isOpen={groupProfilesModalOpen}
          onClose={() => {
            setGroupProfilesModalOpen(false);
            setSelectedClass(null);
          }}
        />
      )}

      <ConfirmationDialog />
    </>
  );
};
