import React, { FC, Suspense } from 'react';
import { BpSubpage } from '../../../components/BpSubpage/BpSubpage';
import { ProcuratOrganizationTable } from '../../../components/Procurat/ProcuratOrganizationTable';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';
import { BpCard } from '../../../components/BpCard/BpCard';
import { LazyLoader } from '@bp/ui-components';
import { NavigationTabs, NavigationTabsType } from '../../../components/NavigationTabs/NavigationTabs';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { backendApi } from '../../../utils/backendApi';
import { showErrorToast } from '../../../utils/showErrorToast';
import { useQuery } from '@tanstack/react-query';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export const InstitutionProcuratSubpage: FC = () => {
  const { t } = useTranslation();

  const perms = usePermissionChecker();

  const tabs: NavigationTabsType[] = [
    {
      title: t('schoolyears.title') + ' / ' + t('classes.titlePlural'),
      path: `classes`,
    },
    {
      title: t('groups.title'),
      path: `persongroups`,
    },
    {
      title: t('procuratImport.department.plural'),
      path: `departments`,
    },
  ];
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();

  const { data, refetch, isFetching } = useQuery({
    queryKey: ['procuratOrga'],
    queryFn: async () => {
      const response = await backendApi.fetch(`/procurat/organization`);
      return await response.json();
    },
  });

  if (data?.error) {
    showErrorToast({ graphQLErrors: [], message: data.error, name: '' });
  }

  const handleSetup = async (procuratOrgaId: string) => {
    const response = await backendApi.post(
      `/procurat/setup`,
      JSON.stringify({
        procuratEnvironment: import.meta.env.VITE_APP_PROCURAT_ENV,
        procuratOrganizationUuid: procuratOrgaId,
        organizationUuid: organizationUuid,
      }),
    );

    if (!response.ok) {
      showErrorToast({ graphQLErrors: [], message: response.statusText, name: '' });
    } else {
      await refetch();
    }
  };

  return (
    <BpSubpage isForbidden={!perms?.isOmniAdmin()}>
      <BpCard header={{ headline: 'Procurat Organisationen' }}>
        {isFetching && <LazyLoader embedded transparent />}
        {!isFetching && <ProcuratOrganizationTable data={data} handleSetup={handleSetup} />}
      </BpCard>
      {!data?.error && (
        <div className='mt-6'>
          <NavigationTabs className='mb-6' tabs={tabs} />
          <Suspense fallback={<LazyLoader embedded transparent />}>{<Outlet />}</Suspense>
        </div>
      )}
    </BpSubpage>
  );
};
