import {
  Button,
  CloseIcon,
  Grid,
  GridColumn,
  GridRow,
  ImportIcon,
  Modal,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { organizationRolesAsString } from '../../../utils/organizationRolesAsString';
import { SingleValue } from 'react-select';
import { ProcuratDepartmentPersonsTableData } from '../../Procurat/ProcuratDepartmentPersonsTable';

export type GrantRoleValues = Record<string, ProfileRoles>;

export const ImportPersonsModal = ({
  open,
  setOpen,
  data,
  handleImport,
}: {
  open: boolean;
  setOpen: (state: boolean) => void;
  data: ProcuratDepartmentPersonsTableData[];
  handleImport: (x: GrantRoleValues) => void;
}) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  const initialValues: GrantRoleValues = {};
  for (const person of data) {
    initialValues[person.id.toString()] = (person.roles[0] as ProfileRoles) ?? ProfileRoles.Other;
  }

  const selectOpts = useMemo(() => {
    return [ProfileRoles.Other, ProfileRoles.Student, ProfileRoles.Teacher, ProfileRoles.Parent].map(
      (r) => ({ label: organizationRolesAsString([r]), value: r }) as SelectOptionType,
    );
  }, []);

  return (
    <Modal
      isOpen={open}
      footer={
        <div>
          <Button onClick={handleClose} hierarchy='secondary' icon={<CloseIcon />}>
            {t('common.cancel')}
          </Button>
        </div>
      }
      onRequestClose={handleClose}
      shouldCloseOnEsc
      title={t('profiles.grantRoles')}
    >
      <Formik<GrantRoleValues> initialValues={initialValues} onSubmit={handleImport}>
        {({ setFieldValue, values, isSubmitting, errors }) => (
          <Form>
            <Grid>
              <GridRow>
                <GridColumn width={6}>&nbsp;</GridColumn>
                <GridColumn width={2}>
                  <Select
                    options={selectOpts}
                    isSearchable={true}
                    onChange={async (event) => {
                      const role = (event as SingleValue<SelectOptionType>)?.value as ProfileRoles;
                      await Promise.all(data.map((person) => setFieldValue(person.id.toString(), role)));
                    }}
                    name={'roleSelect'}
                    dense
                  />
                </GridColumn>
              </GridRow>
              {data.map((person) => (
                <GridRow spacingBottom='none' spacingTop='none' key={person.id} style={{ marginTop: '1rem' }}>
                  <GridColumn width={3} align={'start'} justify={'center'}>
                    {person.firstName} {person.lastName}
                  </GridColumn>
                  <GridColumn width={3} align={'start'} justify={'center'}>
                    {selectOpts.find((v) => person.roles.includes(v.value as string))?.label}
                  </GridColumn>
                  <GridColumn width={2} align={'start'} justify={'center'}>
                    <Select
                      value={selectOpts.find((v) => values[person.id].includes(v.value as string))}
                      options={selectOpts}
                      isSearchable={true}
                      onChange={async (event) => {
                        const role = (event as SingleValue<SelectOptionType>)?.value as ProfileRoles;
                        await setFieldValue(person.id.toString(), role);
                      }}
                      name={'roleSelect'}
                      dense
                    />
                  </GridColumn>
                </GridRow>
              ))}
            </Grid>
            <ModalBottomButtons
              closeButton={{
                callback: () => setOpen(false),
              }}
              submitButton={{ text: t('institution.importPersons'), icon: <ImportIcon /> }}
              isLoading={isSubmitting}
              errors={errors}
            />
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
