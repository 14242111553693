import * as Yup from 'yup';
import i18next from 'i18next';

export const appointmentFormSchema = Yup.object().shape({
  participantsCount: Yup.number()
    .when('bbbMaxAttendees', ([bbbMaxAttendees], schema) =>
      schema.max(bbbMaxAttendees, i18next.t('meetings.tooManyParticipantsWithNumber', { number: bbbMaxAttendees })),
    )
    .when('service', ([service], participantsSchema) => {
      return !service.value
        ? participantsSchema.optional()
        : participantsSchema
            .required(i18next.t('validation.tooFewParticipants'))
            .positive(i18next.t('validation.tooFewParticipants'));
    }),

  service: Yup.object().required(i18next.t('validation.noServiceSelected')),
  duration: Yup.number().min(1, i18next.t('validation.durationTooShort')),
  title: Yup.string()
    .required(i18next.t('validation.required.appointment.title'))
    .min(3, i18next.t('validation.textTooShort')),

  categories: Yup.array().min(1, i18next.t('validation.noLessonType')),
});
