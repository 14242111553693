import { useMemo } from 'react';
import { bookedBBBSlots } from '../utils/bookedBBBSlots';
import { useLicensedProductsQuery } from '../client/bp-graphql-client-defs';
import { useMemoizedCacheTag } from './useMemoizedCacheTag';

export type AvailableService = {
  service: string;
  allowRecording: boolean;
  bookedConnections: number;
};

export const useAvailableServicesForOrganization = (organizationUuid: string = '') => {
  const [{ data }] = useLicensedProductsQuery({
    variables: {
      where: {
        uuid: organizationUuid,
      },
    },
    context: useMemoizedCacheTag('PRODUCTS'),
    pause: organizationUuid === '',
  });

  return useMemo(() => {
    return (
      data?.organizations
        .find((o) => o?.uuid === organizationUuid)
        ?.licensedProducts.map((p) => ({
          service: p.__typename,
          allowRecording: (p.__typename === 'BigBlueButton' && p.allowRecording) ?? false,
          maxParticipantsPerMeeting: p.__typename === 'BigBlueButton' ? p.maxParticipantsPerMeeting : 100,
          bookedConnections:
            p.__typename === 'BigBlueButton'
              ? bookedBBBSlots(p.organizationConnection?.edges[0].properties.version?.toLowerCase())
              : 0,
        })) ?? []
    ).filter((s) => ['BigBlueButton', 'Zoom'].includes(s.service));
  }, [data?.organizations, organizationUuid]);
};
