import { ProcuratGroup, useProcuratGroups } from './hooks/useProcuratGroups';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  LazyLoader,
  Modal,
  Select,
  SelectOptionType,
  showToast,
  useDefaultSelecting,
} from '@bp/ui-components';
import { BpCard } from '../BpCard/BpCard';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProcuratClassesTable, ProcuratClassesTableData } from './ProcuratClassesTable';
import { SingleValue } from 'react-select';
import { backendApi } from '../../utils/backendApi';
import { showSuccessToast } from '../../utils/showSuccessToast';
import { showErrorToast } from '../../utils/showErrorToast';
import { RowSelectionState } from '@tanstack/react-table';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useClassesQuery, useSchoolYearsQuery } from '../../client/bp-graphql-client-defs';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useTranslation } from 'react-i18next';
import { SchoolyearForm } from '../SchoolyearForm/SchoolyearForm';
import { BpSubpage } from '../BpSubpage/BpSubpage';

type ImportData = {
  id: number;
  schoolYearUuid: string | null;
  importParents: boolean;
};

export const ProcuratClasses: React.FC = () => {
  const { groups, isFetching: isGroupsFetching } = useProcuratGroups();
  const { organization, currentSchoolYear } = useContext(OrganizationConfigContext);
  const { t } = useTranslation();

  const schoolYearContext = useMemoizedCacheTag('SCHOOL_YEAR');
  const classesContext = useMemoizedCacheTag('CLASS');

  // State management
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();
  const [selectedClassesForImport, setSelectedClassesForImport] = useState<ProcuratGroup[]>([]);
  const [createSchoolYearModalOpen, setCreateSchoolYearModalOpen] = useState(false);
  const [importWithParents, setImportWithParents] = useState<RowSelectionState>({});
  const [importing, setImporting] = useState(false);
  const [selectedSchoolyearId, setSelectedSchoolyearId] = useState<number>(0);
  const [selectedBpSchoolyearUuid, setSelectedBpSchoolyearUuid] = useState<string | null>(currentSchoolYear.uuid);

  // Queries
  const [{ data: existingSchoolYears }, reloadSchoolYears] = useSchoolYearsQuery({
    variables: { where: { organization: { uuid: organization?.uuid } } },
    context: schoolYearContext,
  });

  const [{ data: classesData }, reloadClasses] = useClassesQuery({
    variables: { where: { organization: { uuid: organization?.uuid } } },
    context: classesContext,
  });

  // Memoized values
  const existingBpSchoolyears = useMemo(() => existingSchoolYears?.schoolYears ?? [], [existingSchoolYears]);

  const procuratSchoolyearsSelectData = useMemo(() => {
    const schoolYears = groups?.schoolYears ?? [];

    return schoolYears
      .map((sy) => ({
        id: sy.id,
        label: `${sy.name} - ${groups?.facilities.find((f) => f.id === sy.parentGroupId)?.name ?? ''}`,
      }))
      .filter((sy) => {
        // show only schoolYears with classes
        const classesInYear = groups?.classes.filter((pclass: ProcuratGroup) => pclass.parentGroupId === sy?.id);
        return classesInYear && classesInYear.length > 0;
      })
      .sort((a, b) => b.label.localeCompare(a.label));
  }, [groups]);

  const procuratSchoolyearSelectOpts = useCreateSelectOptions(procuratSchoolyearsSelectData, 'id', 'label');
  const bpSchoolyearSelectOpts = useCreateSelectOptions(existingBpSchoolyears, 'uuid', 'name');

  const classesTableData = useMemo<ProcuratClassesTableData[]>(() => {
    if (!groups?.classes) return [];

    return groups.classes
      .map((pclass) => {
        const bpClass = classesData?.classes.find((c) => c.foreignRefId?.includes(pclass.id.toString()));
        return {
          ...pclass,
          lastImport: bpClass?.updatedAt ?? null,
          imported: !!bpClass?.uuid,
        };
      })
      .filter((pclass) => pclass.parentGroupId === selectedSchoolyearId)
      .sort((a, b) => (a.sortKey ?? 0) - (b.sortKey ?? 0));
  }, [selectedSchoolyearId, groups, classesData]);

  // Effects
  useEffect(() => {
    setSelectedClassesForImport(
      classesTableData.filter((_, index) => Object.keys(rowSelection).includes(index.toString())),
    );
  }, [classesTableData, rowSelection]);

  // Handlers
  const handleSchoolyearSelectChange = useCallback(
    (e: SingleValue<SelectOptionType>) => {
      onRowSelectionChange({});
      setSelectedSchoolyearId((e?.value as number) ?? 0);
    },
    [onRowSelectionChange],
  );

  const handleImport = useCallback(async () => {
    setImporting(true);
    showToast(t('procuratImport.importStarted'), { type: 'info', theme: 'colored' });

    const importData: ImportData[] = selectedClassesForImport.map((group) => ({
      id: group.id,
      schoolYearUuid: selectedBpSchoolyearUuid,
      importParents: importWithParents[group.id] ?? false,
    }));

    try {
      const resp = await backendApi.put('/procurat/classes/import', JSON.stringify(importData));

      if (resp.ok) {
        await reloadClasses({ requestPolicy: 'network-only' });
        showSuccessToast(t('procuratImport.importSuccess'));
        onRowSelectionChange({});
      } else {
        showErrorToast({ graphQLErrors: [], name: '', message: resp.statusText });
      }
    } catch {
      showErrorToast({ graphQLErrors: [], name: '', message: 'Import failed' });
    } finally {
      setImporting(false);
    }
  }, [selectedClassesForImport, selectedBpSchoolyearUuid, importWithParents, t, reloadClasses, onRowSelectionChange]);

  const handleCreateSchoolYearClose = useCallback(() => {
    reloadSchoolYears();
    setCreateSchoolYearModalOpen(false);
  }, [reloadSchoolYears]);

  const isLoading = isGroupsFetching || importing;

  return (
    <>
      <BpSubpage fullHeight={true}>
        {isLoading && <LazyLoader embedded transparent />}
        {!isLoading && (
          <>
            <BpCard header={{ headline: t('schoolyears.title') }}>
              <Grid>
                <GridRow>
                  <GridColumn width={6}>
                    <Select
                      label={t('procuratImport.schoolYearsInProcurat')}
                      isSearchable={true}
                      options={procuratSchoolyearSelectOpts}
                      placeholder={t('procuratImport.selectSchoolYear')}
                      value={procuratSchoolyearSelectOpts.find((o) => o.value === selectedSchoolyearId)}
                      onChange={(e) => handleSchoolyearSelectChange(e as SingleValue<SelectOptionType>)}
                      name='schoolYear'
                    />
                  </GridColumn>
                  <GridColumn width={4}>
                    <Select
                      label={t('procuratImport.importToSchoolYear')}
                      isSearchable={true}
                      options={bpSchoolyearSelectOpts}
                      placeholder={t('procuratImport.selectSchoolYear')}
                      value={bpSchoolyearSelectOpts.find((o) => o.value === selectedBpSchoolyearUuid)}
                      onChange={(e) =>
                        setSelectedBpSchoolyearUuid((e as SingleValue<SelectOptionType> | null)?.value as string)
                      }
                      name='targetSchoolYear'
                    />
                  </GridColumn>
                  <GridColumn width={2} style={{ marginTop: '20px' }}>
                    <Button onClick={() => setCreateSchoolYearModalOpen(true)} hierarchy='secondary'>
                      {t('common.addType', { type: t('schoolyears.titleSingular') })}
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </BpCard>
            <BpCard className='mt-3' header={{ headline: t('classes.titlePlural') }} minHeight='50vh'>
              <ProcuratClassesTable
                key={selectedSchoolyearId}
                setImportWithParents={setImportWithParents}
                importWithParents={importWithParents}
                data={classesTableData}
                rowSelection={rowSelection}
                onRowSelectionChange={onRowSelectionChange}
                handleImport={handleImport}
              />
            </BpCard>
          </>
        )}
      </BpSubpage>
      <Modal
        title={t('common.schoolyear')}
        isOpen={createSchoolYearModalOpen}
        onRequestClose={handleCreateSchoolYearClose}
      >
        <SchoolyearForm onClose={handleCreateSchoolYearClose} />
      </Modal>
    </>
  );
};
