import dayjs from 'dayjs';
import { LazyLoader, Modal } from '@bp/ui-components';
import {
  useBpAssignmentsQuery,
  useBpDeleteGroupMutation,
  useProfileCoursesQuery,
} from '../../client/bp-graphql-client-defs';
import { BpPage } from '../../components/BpPage/BpPage';
import { CourseForm } from '../../components/CourseForm/CourseForm';
import { CoursesTableStudent } from '../../components/CoursesTable/CoursesTableStudent';
import { CoursesTableTeacher, CourseTableType } from '../../components/CoursesTable/CoursesTableTeacher';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { Suspense, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CourseSettingsForm } from '../../components/CourseSettingsForm/CourseSettingsForm';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useGetMatrixRooms } from 'hooks/matrix/useGetMatrixRooms';
import { useGroupOrCoursesOnlineEvents } from '../../hooks/useCalendarEvents';

export const CoursesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const perms = usePermissionChecker();
  const { pimAuthClaims, updateClaims } = useAuthClaims();

  const context = useMemoizedCacheTag('COURSE');
  const assignmentContext = useMemoizedCacheTag('ASSIGNMENT');

  const { currentSchoolYear } = useContext(OrganizationConfigContext);

  const [claimsTrigger, setClaimsTrigger] = useState<number>(0);

  const [, deleteCourse] = useBpDeleteGroupMutation();

  const [{ data }] = useProfileCoursesQuery({
    variables: {
      organizations: pimAuthClaims.getAllowedOrganizationUuids(),
      profile: pimAuthClaims.getProfile().uuid,
    },
    context,
  });

  const onlineEvents = useGroupOrCoursesOnlineEvents((data?.groups ?? []).map((c) => c.uuid) ?? []);

  const [{ data: assignmentsQueryResult }] = useBpAssignmentsQuery({
    context: assignmentContext,
  });

  const rooms = useGetMatrixRooms();

  // Memoize filtered courses
  const filteredCourses = useMemo(() => {
    return data?.groups.filter((c) => c.schoolYear?.uuid === currentSchoolYear.uuid || c.isCommunity) ?? [];
  }, [data?.groups, currentSchoolYear.uuid]);

  // Memoize message notifications
  const courseNotifications = useMemo(() => {
    const notifications: Record<string, boolean> = {};

    filteredCourses.forEach((course) => {
      const notificationRoom = rooms?.find((room) => room.name.includes(course.uuid));
      let newMessages = false;

      if (notificationRoom?.getRoomUnreadNotificationCount) {
        newMessages = notificationRoom.getRoomUnreadNotificationCount() > 0;
      }

      if (!newMessages && assignmentsQueryResult?.assignments) {
        const courseAssignments = assignmentsQueryResult.assignments.filter(
          (assignment) => assignment.holder.group.uuid === course.uuid,
        );

        courseAssignments.some((assignment) => {
          return assignment.submissions.some((submission) => {
            const room = rooms?.find((room) => room?.name?.includes(submission.uuid));
            return (room?.getRoomUnreadNotificationCount() ?? 0) > 0;
          });
        });
      }

      notifications[course.uuid] = newMessages;
    });

    return notifications;
  }, [filteredCourses, rooms, assignmentsQueryResult?.assignments]);

  // Final table data transformation
  const tableData: CourseTableType[] = useMemo(() => {
    return filteredCourses.map((course) => ({
      uuid: course.uuid,
      organizationUuid: course.organization.uuid,
      name: course.name,
      meetingInProgress:
        onlineEvents.find((v) => v.originUuid === course.uuid)?.virtualLocations.find((v) => v.running)?.running ??
        false,
      count: 0,
      newMessage: courseNotifications[course.uuid],
      community: course.isCommunity,
      source:
        course.managedBy !== 'bp' ? course.managedBy : course.admins.map((admin) => admin.displayNameShort).toString(),
      nextcloud: {
        enabled: course.externalServices?.nextcloud?.enabled ?? false,
        lastSync: course.externalServices?.nextcloud?.lastSync
          ? dayjs(course.externalServices?.nextcloud.lastSync)
          : undefined,
      },
      threema: {
        enabled: course.externalServices?.threema?.enabled ?? false,
        lastSync: course.externalServices?.threema?.lastSync
          ? dayjs(course.externalServices?.threema.lastSync)
          : undefined,
      },
    }));
  }, [filteredCourses, onlineEvents, courseNotifications]);

  const [modalMode, setModalMode] = useState<{
    open: boolean;
    mode: 'create' | 'edit' | '';
    courseUuid?: string | null;
  }>({ open: false, mode: '', courseUuid: null });

  const handleAdd = () => {
    setModalMode({ open: true, mode: 'create' });
  };

  const handleEdit = (uuid: string) => {
    setModalMode({ open: true, mode: 'edit', courseUuid: uuid });
  };

  const handleDelete = async (uuid: string) => {
    await deleteCourse(
      {
        id: uuid,
      },
      context,
    );
  };

  const [settingsModalOpen, setSettingsModalOpen] = useState<{
    open: boolean;
    courseUuid: string | null;
  }>({ open: false, courseUuid: null });

  const handleSettings = (courseUuid: string) => {
    setSettingsModalOpen({ open: true, courseUuid: courseUuid });
  };

  const handleSettingsClose = () => {
    setSettingsModalOpen({ open: false, courseUuid: null });
  };

  return (
    <BpPage title={t('courses.myCourses', { year: currentSchoolYear?.name })} isForbidden={false}>
      {perms?.canCreateCourse() ? (
        <CoursesTableTeacher
          data={tableData}
          onNavigate={(uuid) => navigate('/courses/' + uuid)}
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={(uuid) => handleDelete(uuid)}
          onSettings={(uuid) => handleSettings(uuid)}
        />
      ) : (
        <CoursesTableStudent data={tableData} onNavigate={(uuid) => navigate('/courses/' + uuid)} />
      )}

      <Modal
        isOpen={modalMode.open}
        onRequestClose={() => setModalMode({ open: false, mode: '', courseUuid: null })}
        title={modalMode.mode === 'edit' ? t('courses.editCourse') : t('courses.addCourse')}
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded forceHeight='55vh' />}>
          <CourseForm
            courseUuid={modalMode.courseUuid ?? ''}
            onClose={async () => {
              setModalMode({ open: false, mode: '', courseUuid: null });
              await updateClaims(() => setClaimsTrigger(claimsTrigger + 1));
            }}
          />
        </Suspense>
      </Modal>

      <Modal
        title={t('courses.coursesSettings')}
        isOpen={settingsModalOpen.open}
        shouldCloseOnEsc={true}
        onRequestClose={handleSettingsClose}
      >
        <CourseSettingsForm onClose={handleSettingsClose} courseUuid={settingsModalOpen.courseUuid} />
      </Modal>
    </BpPage>
  );
};
