import React, { useMemo } from 'react';
import { Button, ImportIcon, Table, TableColumns } from '@bp/ui-components';
import { ProcuratGroup } from './hooks/useProcuratGroups';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { niceDate } from '../../utils/dateCalculations';
import { GroupAsCourseQuery } from '../../client/bp-graphql-client-defs';
import { useTranslation } from 'react-i18next';

type ProcuratClassesTableProps = {
  data: ProcuratGroup[];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  handleImport: () => void;
  groupsData: GroupAsCourseQuery | undefined;
};

export const ProcuratGroupsTable = ({
  data,
  rowSelection,
  onRowSelectionChange,
  handleImport,
  groupsData,
}: ProcuratClassesTableProps) => {
  const { t } = useTranslation();

  const groupColumns: TableColumns<ProcuratGroup>[] = useMemo(() => {
    const findBpClassModificationDate = (id: number) => {
      const group = groupsData?.groups.find((c) => !!c.foreignRefId?.includes(id.toString()));
      return group?.updatedAt ? group?.updatedAt : group?.createdAt ? group?.createdAt : null;
    };

    return [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
      },
      {
        id: 'lastImport',
        accessorKey: 'lastImport',
        header: t('common.lastUpdated'),
        cell: (cell) => {
          return niceDate(findBpClassModificationDate(cell.row.original.id), 'short', 'short') ?? '-';
        },
        type: 'string',
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: t('common.imported'),
        accessorFn: (item) => findBpClassModificationDate(item.id), // if it has a date, it is already imported
        type: 'boolean',
        size: 150,
      },
    ];
  }, [groupsData?.groups]);

  return (
    <>
      <Table<ProcuratGroup>
        showActionBar
        actionBarSettings={{
          showSelectedRowsCount: true,
          showExpertFilter: false,
          globalFilterPlaceholder: t('common.name'),
          extendedActionsRight: (
            <Button
              hierarchy='tertiary'
              icon={<ImportIcon />}
              onClick={handleImport}
              disabled={Object.keys(rowSelection).length < 1}
            >
              {t('institution.importGroups')}
            </Button>
          ),
        }}
        columns={groupColumns}
        data={data}
        onRowSelectionChange={onRowSelectionChange}
        rowSelection={rowSelection}
        canScroll={true}
        showSelect={true}
      />
    </>
  );
};
