import { Suspense, useContext, useState, lazy, useMemo, useEffect } from 'react';
import { findClosestEventToNow, getDaytimeGreeting, isBefore } from '../../utils/dateCalculations';
import styles from './Home.module.scss';
import { useNavigate } from 'react-router-dom';
import { AddIcon, Grid, GridColumn, GridRow, LazyLoader, Modal, Tab } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { BpHeader } from '../../components/BpHeader/BpHeader';
import { BpPage } from '../../components/BpPage/BpPage';
import { BpCard } from '../../components/BpCard/BpCard';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import classNames from 'classnames';
import { OrganizationConfigContext } from '../../context/OrganizationConfigContext';
import { useRefreshOnEvent } from 'hooks/matrix/useRefreshOnEvent';
import { ProfileRoles } from '@bp/pim-auth-constants';
import { useMemoizedCacheTag } from '../../hooks/useMemoizedCacheTag';
import { useDashboardCalendarEvents, useGroupOrCoursesOnlineEvents } from '../../hooks/useCalendarEvents';
import { useGetMatrixRoomByName } from '../../hooks/matrix/useGetMatrixRoomByName';
import { NotificationReason, RoomNames } from '../../utils/matrixClient';
import { useGetAnnouncements } from '../../hooks/matrix/useGetAnnouncements';
import { AnnouncementFormType } from '../../components/AnnouncementForm/AnnouncementForm';
import { backendApi } from '../../utils/backendApi';
import {
  useBpAssignmentsQuery,
  useProfileCoursesQuery,
  useProfileGroupsQuery,
} from '../../client/bp-graphql-client-defs';
import type { CoursesPreviewTableType } from '../../components/CoursesPreviewTable/CoursesPreviewTable';
import type { GroupsPreviewTableType } from '../../components/GroupsPreviewTable/GroupsPreviewTable';
import { useMatrixNotifications } from '../../hooks/matrix/useMatrixNotifications';
import { useMatrixLifecycle } from '../../hooks/matrix/useMatrixLifecycle';

// Create wrapper components for lazy loading
const createLazyComponent = <T extends object>(
  importPromise: Promise<{ [key: string]: React.ComponentType<T> }>,
  exportName: string,
) =>
  lazy(() =>
    importPromise.then((module) => ({
      default: module[exportName] as React.ComponentType<T>,
    })),
  );

// Lazy loaded components
const BpCalendarLazy = createLazyComponent(import('../../components/BpCalendar/BpCalendar'), 'BpCalendar');
const EventsListLazy = createLazyComponent(import('../../components/EventsList/EventsList'), 'EventsList');
const AnnouncementsListLazy = createLazyComponent(
  import('../../components/AnnouncementsList/AnnouncementsList'),
  'AnnouncementsList',
);
const LinkListLazy = createLazyComponent(import('../../components/LinkList/LinkList'), 'LinkList');
const ListsBarLazy = createLazyComponent(import('../../components/ListsBar/ListsBar'), 'ListsBar');
const CoursesPreviewTableLazy = createLazyComponent(
  import('../../components/CoursesPreviewTable/CoursesPreviewTable'),
  'CoursesPreviewTable',
);
const GroupsPreviewTableLazy = createLazyComponent(
  import('../../components/GroupsPreviewTable/GroupsPreviewTable'),
  'GroupsPreviewTable',
);
const OpenAssignmentsOverviewLazy = createLazyComponent(
  import('../../components/OpenAssignmentsOverview/OpenAssignmentsOverview'),
  'OpenAssignmentsOverview',
);
const AnnouncementFormLazy = createLazyComponent(
  import('../../components/AnnouncementForm/AnnouncementForm'),
  'AnnouncementForm',
);
const AppointmentFormLazy = createLazyComponent(
  import('../../components/AppointmentForm/AppointmentForm'),
  'AppointmentForm',
);

// Add batched query hook
const useHomePageData = (profileUuid: string, organizationUuids: string[]) => {
  const courseContext = useMemoizedCacheTag('COURSE_LIST');
  const groupContext = useMemoizedCacheTag('GROUP_LIST');
  const assignmentContext = useMemoizedCacheTag('ASSIGNMENT');

  const [{ data: courseData }] = useProfileCoursesQuery({
    variables: {
      organizations: organizationUuids,
      profile: profileUuid,
    },
    context: courseContext,
    requestPolicy: 'cache-and-network', // Use cache first, then update from network
  });

  const [{ data: groupsData }] = useProfileGroupsQuery({
    variables: {
      organizations: organizationUuids,
      profile: profileUuid,
    },
    context: groupContext,
    requestPolicy: 'cache-and-network',
  });

  const [{ data: assignmentsQueryResult }] = useBpAssignmentsQuery({
    context: assignmentContext,
    requestPolicy: 'cache-and-network',
  });

  return {
    courseData,
    groupsData,
    assignmentsQueryResult,
  };
};

// Type for the event click handler
type EventClickHandler = (uuid: string) => void;

export const Home = () => {
  const { t } = useTranslation();
  const perms = usePermissionChecker();
  const navigate = useNavigate();
  const { pimAuthClaims } = useAuthClaims();
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const profileUuid = pimAuthClaims.getProfile().uuid;
  const organizationUuids = pimAuthClaims.getAllowedOrganizationUuids();

  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);

  const { currentSchoolYear } = useContext(OrganizationConfigContext);
  const { dashboardEvents } = useDashboardCalendarEvents(profileUuid);

  useRefreshOnEvent();

  const closestEvent = findClosestEventToNow(dashboardEvents);
  const [scrollUuid, setScrollUuid] = useState<string | null>(closestEvent ? closestEvent.uuid : null);
  const [randomRedrawString, setRandomRedrawString] = useState<string>('');

  const firstName = pimAuthClaims.getProfile()?.firstName ?? '';

  const { getRoom } = useGetMatrixRoomByName();
  const room = getRoom(organizationUuid, RoomNames.Organization);
  const announcements = useGetAnnouncements(room);

  // Use the batched query hook
  const { courseData, groupsData, assignmentsQueryResult } = useHomePageData(profileUuid, organizationUuids);

  // Use optimized Matrix lifecycle management
  const matrixClientPromise = useMatrixLifecycle();

  // Add this effect to ensure Matrix client is properly initialized
  useEffect(() => {
    void matrixClientPromise;
  }, [matrixClientPromise]);

  // Get all relevant UUIDs for Matrix notifications
  const targetUuids = useMemo(() => {
    const uuids = new Set<string>();

    // Add course UUIDs
    courseData?.groups?.forEach((course) => {
      if (course?.uuid) {
        uuids.add(course.uuid);
        // Add submission UUIDs for the course
        assignmentsQueryResult?.assignments
          ?.filter((assignment) => assignment?.holder?.group?.uuid === course.uuid)
          ?.forEach((assignment) => {
            assignment.submissions?.forEach((submission) => {
              if (submission?.uuid) {
                uuids.add(submission.uuid);
              }
            });
          });
      }
    });

    // Add group UUIDs
    groupsData?.groups?.forEach((group) => {
      if (group?.uuid) {
        uuids.add(group.uuid);
      }
    });

    return Array.from(uuids);
  }, [courseData?.groups, groupsData?.groups, assignmentsQueryResult?.assignments]);

  // Use the optimized Matrix notifications hook
  const matrixNotifications = useMatrixNotifications(targetUuids);

  const handleAnnouncementFormSubmit = (values?: AnnouncementFormType): void => {
    setAnnouncementModalOpen(false);
    if (values) {
      void backendApi.notify({
        type: NotificationReason.OrganizationAnnouncement,
        content: values.message,
        title: values.title,
        organizationUuid: organizationUuid,
      });
    }
  };

  const handleEventClick: EventClickHandler = (uuid: string): void => {
    setRandomRedrawString(Date.now().toString());
    setScrollUuid(uuid);
  };

  const myAssignments = assignmentsQueryResult?.assignments.filter((a) =>
    a.submissions.some((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid),
  );

  const onlineEvents = useGroupOrCoursesOnlineEvents([
    ...(courseData?.groups ?? []).map((c) => c.uuid),
    ...(groupsData?.groups ?? []).map((c) => c.uuid),
  ]);

  const coursesPreviewData: CoursesPreviewTableType[] =
    courseData?.groups
      ?.filter((c) => c?.schoolYear?.uuid === currentSchoolYear?.uuid || c?.isCommunity)
      ?.map((course) => {
        const notification = matrixNotifications.get(course.uuid);
        let newMessages = notification?.hasNewMessages ?? false;

        if (!newMessages) {
          // Check submissions notifications
          newMessages =
            assignmentsQueryResult?.assignments
              ?.filter((assignment) => assignment?.holder?.group?.uuid === course.uuid)
              ?.some((assignment) => {
                return assignment.submissions?.some((submission) => {
                  const submissionNotification = matrixNotifications.get(submission.uuid);
                  return submissionNotification?.hasNewMessages ?? false;
                });
              }) ?? false;
        }

        return {
          uuid: course.uuid,
          organization: course.organization,
          name: course.name,
          meetingInProgress:
            onlineEvents?.find((v) => v?.originUuid === course.uuid)?.virtualLocations?.find((v) => v?.running)
              ?.running ?? false,
          count: notification?.unreadCount ?? 0,
          newMaterial: newMessages,
        };
      }) ?? [];

  const groupsPreviewData: GroupsPreviewTableType[] =
    groupsData?.groups?.map((group) => {
      const notification = matrixNotifications.get(group.uuid);
      return {
        uuid: group.uuid,
        organization: group.organization,
        name: group.name,
        meetingInProgress:
          onlineEvents?.find((v) => v?.originUuid === group.uuid)?.virtualLocations?.find((v) => v?.running)?.running ??
          false,
        count: notification?.unreadCount ?? 0,
        newMaterial: notification?.hasNewMessages ?? false,
      };
    }) ?? [];

  const teacherTabs = [
    {
      title: t('common.calendar'),
      value: 'calendar',
      content: (
        <div className={classNames(styles.tab, styles['calendar-tab'])}>
          <BpCard className={styles.calendar} noPadding showBorder hideBackground>
            <BpCalendarLazy
              events={dashboardEvents}
              showAddButton={perms?.isOrganizationAdmin(pimAuthClaims.getOrganization())}
              onAdd={() => setAppointmentModalOpen(true)}
              isOnWhite
              eventClick={handleEventClick}
            />
          </BpCard>
          <EventsListLazy
            className={styles.events}
            isOnWhite
            showCompactEvents
            events={dashboardEvents}
            scrollToUuid={scrollUuid}
            randomRedrawString={randomRedrawString}
          />
        </div>
      ),
    },
    {
      content: (
        <div className={classNames(styles.tab, styles['timetable-tab'])}>
          <img src='./timetable-mockup.png' alt={'Beispiel Stundenplan'} />
        </div>
      ),
      title: t('common.timetable'),
      value: 'timetable',
    },
  ];

  const activeAssignments = myAssignments?.filter((a) => isBefore(new Date(), a.dueDate?.date)) ?? [];

  const studentTabs = [
    {
      content: (
        <div className={classNames(styles.tab, styles['gantt-tab'])}>
          <OpenAssignmentsOverviewLazy
            assignments={activeAssignments}
            onNavigate={async (uuid) => {
              const courseUuid = myAssignments?.find((a) => a.uuid === uuid)?.holder.group.uuid ?? '0';
              navigate(`/courses/${courseUuid}/assignments/${uuid}`);
            }}
          />
        </div>
      ),
      title: t('assignments.title'),
      value: 'assignments',
    },
    {
      content: (
        <div className={classNames(styles.tab, styles['timetable-tab'])}>
          <img src='./timetable-mockup.png' alt={'Beispiel Stundenplan'} />
        </div>
      ),
      title: t('common.timetable'),
      value: 'timetable',
    },
  ];

  const classes = classNames(styles.home, {
    [styles['no-bar']]: !perms?.canCreateCourse(),
  });

  const handleCourseNavigate = (uuid: string): void => {
    navigate('/courses/' + uuid);
  };

  const handleGroupNavigate = (uuid: string): void => {
    navigate('/groups/' + uuid);
  };

  return (
    <BpPage noHeadlineMargin className={classes} title='' isForbidden={false}>
      <BpHeader breakLine headline={getDaytimeGreeting(firstName)} size='l' noMargin={perms?.canCreateCourse()} />
      {perms?.canCreateCourse() && (
        <Suspense fallback={<LazyLoader embedded transparent />}>
          <ListsBarLazy />
        </Suspense>
      )}
      <Grid>
        <GridRow mobileGap='var(--grid-column-gap)'>
          <GridColumn width={4}>
            <BpCard
              className='mb-7'
              fixedHeight='432px'
              noPadding
              canScroll
              header={{ headline: t('courses.title'), subHeadline: coursesPreviewData.length ?? 0 }}
            >
              <Suspense fallback={<LazyLoader embedded transparent />}>
                <CoursesPreviewTableLazy data={coursesPreviewData} onNavigate={handleCourseNavigate} />
              </Suspense>
            </BpCard>
            <BpCard
              fixedHeight='432px'
              noPadding
              canScroll
              header={{ headline: t('groups.title'), subHeadline: groupsData?.groups.length ?? 0 }}
            >
              <Suspense fallback={<LazyLoader embedded transparent />}>
                <GroupsPreviewTableLazy data={groupsPreviewData} onNavigate={handleGroupNavigate} />
              </Suspense>
            </BpCard>
          </GridColumn>
          <GridColumn width={8}>
            <GridRow spacingTop='none'>
              <BpCard className={styles['calendar-card']} noPadding minHeight='584px'>
                <Suspense fallback={<LazyLoader embedded transparent />}>
                  <Tab
                    className={styles['tab-header']}
                    tabs={
                      perms?.hasOrganizationRole(ProfileRoles.Student, pimAuthClaims.getOrganization())
                        ? studentTabs
                        : teacherTabs
                    }
                  />
                </Suspense>
              </BpCard>
            </GridRow>
            <GridRow mobileGap='var(--grid-column-gap)'>
              <GridColumn width={6}>
                <BpCard
                  noPadding
                  fixedHeight='280px'
                  canScroll
                  header={{
                    headline: t('announcements.title'),
                    actions: perms?.canCreateOrganizationAnnouncment({ uuid: organizationUuid })
                      ? [
                          {
                            text: t('common.add'),
                            icon: <AddIcon />,
                            callback: () => {
                              setAnnouncementModalOpen(true);
                            },
                          },
                        ]
                      : [],
                  }}
                >
                  <Suspense fallback={<LazyLoader embedded transparent />}>
                    <AnnouncementsListLazy
                      emptyStateSettings={{ hideIcon: true, forcedHeight: '' }}
                      announcements={announcements}
                      hasPermissionToDelete={
                        perms?.canCreateOrganizationAnnouncment({
                          uuid: organizationUuid,
                        }) ?? false
                      }
                    />
                  </Suspense>
                </BpCard>
              </GridColumn>
              <GridColumn width={6}>
                <BpCard fixedHeight='280px' header={{ headline: t('links.title') }}>
                  <Suspense fallback={<LazyLoader embedded transparent />}>
                    <LinkListLazy />
                  </Suspense>
                </BpCard>
              </GridColumn>
            </GridRow>
          </GridColumn>
        </GridRow>
      </Grid>

      <Modal
        isOpen={appointmentModalOpen}
        onRequestClose={() => setAppointmentModalOpen(false)}
        isFormModal
        hideHeader
        hideFooter
      >
        <Suspense fallback={<LazyLoader embedded transparent forceHeight='40vh' />}>
          <AppointmentFormLazy
            holderUuid={organizationUuid}
            context='Organization'
            isModal
            onClose={() => setAppointmentModalOpen(false)}
          />
        </Suspense>
      </Modal>

      <Modal
        isOpen={announcementModalOpen}
        onRequestClose={() => setAnnouncementModalOpen(false)}
        isFormModal
        hideFooter
        title={t('announcements.new')}
        width='s'
      >
        <Suspense fallback={<LazyLoader embedded transparent />}>
          <AnnouncementFormLazy onSubmit={handleAnnouncementFormSubmit} />
        </Suspense>
      </Modal>
    </BpPage>
  );
};
