import { useMemo } from 'react';
import { useGetMatrixRooms } from './useGetMatrixRooms';
import type { Room } from 'matrix-js-sdk';

type NotificationInfo = {
  hasNewMessages: boolean;
  unreadCount: number;
};

export const useMatrixNotifications = (targetUuids: string[]): Map<string, NotificationInfo> => {
  const rooms = useGetMatrixRooms();

  return useMemo(() => {
    const notifications = new Map<string, NotificationInfo>();
    if (!rooms?.length || !targetUuids.length) return notifications;

    // Create a Map to store room info by UUID for O(1) lookup
    const roomsByUuid = new Map<string, Room>();
    
    // First pass: organize rooms by UUID
    for (const room of rooms) {
      const roomName = room.name;
      if (!roomName) continue;

      // Find matching UUID efficiently
      const matchingUuid = targetUuids.find(uuid => roomName.includes(uuid));
      if (matchingUuid) {
        roomsByUuid.set(matchingUuid, room);
      }
    }

    // Second pass: create notifications
    for (const uuid of targetUuids) {
      const room = roomsByUuid.get(uuid);
      if (room) {
        const unreadCount = room.getUnreadNotificationCount() ?? 0;
        notifications.set(uuid, {
          hasNewMessages: unreadCount > 0,
          unreadCount
        });
      } else {
        // Set default values for UUIDs without rooms
        notifications.set(uuid, {
          hasNewMessages: false,
          unreadCount: 0
        });
      }
    }

    return notifications;
  }, [rooms, targetUuids]);
}; 