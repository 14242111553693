import React, { useCallback, useMemo } from 'react';
import { Button, Checkbox, ImportIcon, Table, TableColumns } from '@bp/ui-components';
import { ProcuratGroup } from './hooks/useProcuratGroups';
import { OnChangeFn, RowSelectionState } from '@tanstack/react-table';
import { niceDate } from '../../utils/dateCalculations';
import { useTranslation } from 'react-i18next';

export type ProcuratClassesTableData = ProcuratGroup & {
  lastImport: string;
  imported: boolean;
};

type ProcuratClassesTableProps = {
  data: ProcuratClassesTableData[];
  rowSelection: RowSelectionState;
  onRowSelectionChange: OnChangeFn<RowSelectionState>;
  handleImport: () => void;
  importWithParents: RowSelectionState;
  setImportWithParents: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const ProcuratClassesTable: React.FC<ProcuratClassesTableProps> = ({
  data,
  rowSelection,
  onRowSelectionChange,
  handleImport,
  importWithParents,
  setImportWithParents,
}) => {
  const { t } = useTranslation();

  const handleCheckBoxChange = useCallback(
    (group: ProcuratGroup) => {
      setImportWithParents((prev) => ({
        ...prev,
        [group.id]: !prev[group.id],
      }));
    },
    [setImportWithParents],
  );

  const groupColumns: TableColumns<ProcuratClassesTableData>[] = useMemo(
    () => [
      {
        header: t('common.name'),
        id: 'name',
        accessorKey: 'name',
        canExpand: false,
      },
      {
        header: t('procuratImport.withParents'),
        id: 'select',
        canExpand: false,
        cell: ({ row }) => (
          <Checkbox
            name={row.id}
            onChange={() => handleCheckBoxChange(row.original)}
            checked={importWithParents[row.original.id] ?? false}
            disabled={!rowSelection[row.id]}
          />
        ),
      },
      {
        id: 'schoolYear',
        accessorKey: 'schoolYear',
        header: t('common.schoolyear'),
        type: 'string',
        size: 150,
        canExpand: true,
      },
      {
        id: 'lastImport',
        accessorKey: 'lastImport',
        header: t('common.lastUpdated'),
        cell: ({ row }) => niceDate(row.original.lastImport, 'short', 'short') ?? '-',
        type: 'string',
        canExpand: true,
      },
      {
        id: 'imported',
        accessorKey: 'imported',
        header: t('common.imported'),
        type: 'boolean',
        size: 150,
      },
    ],
    [t, importWithParents, rowSelection, handleCheckBoxChange],
  );

  const hasSelectedRows = Object.keys(rowSelection).length > 0;

  return (
    <Table<ProcuratClassesTableData>
      showActionBar
      actionBarSettings={{
        showSelectedRowsCount: true,
        showExpertFilter: false,
        globalFilterPlaceholder: t('common.name'),
        extendedActionsRight: (
          <Button hierarchy='tertiary' icon={<ImportIcon />} onClick={handleImport} disabled={!hasSelectedRows}>
            {t('institution.importClasses')}
          </Button>
        ),
      }}
      columns={groupColumns}
      data={data}
      onRowSelectionChange={onRowSelectionChange}
      rowSelection={rowSelection}
      canScroll={true}
      showSelect={true}
      minHeight={500}
    />
  );
};
